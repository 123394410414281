import { Button } from 'antd';
import { useNavigate } from 'react-router';

import { useCreateSaasEnvironmentMutation, SaasEnvironmentsDocument } from 'src/graphql/schema';
import { message } from 'src/components/Misc';

import type { SizeType } from 'antd/lib/config-provider/SizeContext';

type Props = {
  titleButton?: string;
  size?: SizeType;
};

const CreateNewEnvironmentContainer = ({ titleButton = '+ New SaaS Escrow', size = 'middle' }: Props) => {
  const navigate = useNavigate();
  const [createSaasEnvironment, { loading }] = useCreateSaasEnvironmentMutation({
    refetchQueries: [SaasEnvironmentsDocument],
  });

  const onClick = async () => {
    try {
      const { data } = await createSaasEnvironment();
      if (data?.createSaasEnvironment?.success) {
        navigate(`/saas-escrows/${data?.createSaasEnvironment?.saasEnvironment?.id}/providers`);
      } else {
        throw new Error(data?.createSaasEnvironment?.errors?.[0]);
      }
    } catch (error: unknown) {
      if (error instanceof Error) message.error(error.message);
    }
  };

  return (
    <Button type="primary" size={size} loading={loading} onClick={onClick}>
      {titleButton}
    </Button>
  );
};

export default CreateNewEnvironmentContainer;
