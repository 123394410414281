import { useAddDepositSlice } from 'src/slices';

import { createRequiredContext } from '../createRequiredContext';

type Props = {
  children: React.ReactNode;
  refetch?: () => void;
  softwareType: 'escrow' | 'backup';
};

const [useAddDeposit, AddDepositProvider] = createRequiredContext<{
  ui: ReturnType<typeof useAddDepositSlice>;
  refetch?: () => void;
  softwareType: Props['softwareType'];
}>();

const AddDepositContextProvider = ({ refetch, children, softwareType }: Props) => {
  const ui = useAddDepositSlice();
  const providerValue = {
    ui,
    refetch,
    softwareType,
  };

  return <AddDepositProvider value={providerValue}>{children}</AddDepositProvider>;
};

export { useAddDeposit, AddDepositContextProvider };
