import { Button } from 'antd';
import { RightOutlined, CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router';

import styles from 'src/components/Escrows/Table/EscrowsTable.module.scss';

import EscrowInvitationActionsContainer from './EscrowInvitationActionsContainer';

type Props = {
  id: string;
  invitationId?: string;
  isDraft: boolean;
  onRemove: (id: string) => void;
};

const EscrowTableActionsContainer = ({ id, invitationId, isDraft, onRemove }: Props) => {
  if (invitationId) return <EscrowInvitationActionsContainer escrowInvitationId={invitationId} />;

  return (
    <div className="d-flex">
      {isDraft && (
        <div className={styles['btn-item']}>
          <Button type="text" className={`${styles.btn} ${styles['btn-remove']}`} onClick={() => onRemove(id)}>
            <CloseOutlined />
          </Button>
        </div>
      )}

      <div className={styles['btn-item']}>
        <Link to={`/escrows/${id}/basics`}>
          <Button type="text" className={`${styles.btn} ${styles['btn-next']}`}>
            <RightOutlined />
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default EscrowTableActionsContainer;
