import { useNavigate, useParams } from 'react-router';

import { JourneyNavigation } from 'src/components/Journey/Navigation';
import { EscrowRoutesEnum } from 'src/pages/Escrow';
import { useEscrow } from 'src/context/escrow';
import { EscrowStatusEnum } from 'src/graphql/schema';

const EscrowNavigationContainer = () => {
  const navigate = useNavigate();
  const { escrowId, '*': activePath } = useParams<{
    escrowId: string;
    '*': EscrowRoutesEnum;
  }>();
  const {
    escrow,
    ui: {
      isSuggestChangesMode,
      members: { isEditAccessMode },
    },
  } = useEscrow();

  if (isEditAccessMode) return null;

  const routes = [
    EscrowRoutesEnum.Basics,
    EscrowRoutesEnum.Members,
    EscrowRoutesEnum.Depositor,
    EscrowRoutesEnum.Beneficiary,
    EscrowRoutesEnum.Notifications,
    EscrowRoutesEnum.Agreement,
  ];

  if (escrow.status !== EscrowStatusEnum.Draft && escrow.status !== EscrowStatusEnum.AdminReview) {
    routes.push(EscrowRoutesEnum.Preview);
  }

  if (
    [
      EscrowStatusEnum.Active,
      EscrowStatusEnum.Signed,
      EscrowStatusEnum.PendingTermination,
      EscrowStatusEnum.Terminated,
    ].includes(escrow.status)
  ) {
    routes.push(EscrowRoutesEnum.Deposits);
  }

  if ([EscrowStatusEnum.Active].includes(escrow.status)) {
    routes.push(EscrowRoutesEnum.Certificate, EscrowRoutesEnum.Reports);
  }

  const activeRouteIndex = routes.findIndex((route) => route === activePath);

  const goToPreviousRoute = () => {
    if (activeRouteIndex > 0) {
      navigate(`/escrows/${escrowId}/${routes[activeRouteIndex - 1]}`);
    }
  };

  const goToNextRoute = () => {
    if (activeRouteIndex < routes.length - 1) {
      navigate(`/escrows/${escrowId}/${routes[activeRouteIndex + 1]}`);
    }
  };

  if (isSuggestChangesMode || (activePath && !Object.values(EscrowRoutesEnum).includes(activePath))) return null;

  return (
    <JourneyNavigation
      isPrevHidden={!activeRouteIndex}
      isNextHidden={activeRouteIndex === routes.length - 1}
      onPrev={goToPreviousRoute}
      onNext={goToNextRoute}
    />
  );
};

export default EscrowNavigationContainer;
