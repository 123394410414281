import {
  Home,
  LayoutList,
  LayoutGrid,
  ChevronRight,
  WalletCards,
  Settings,
  CircleHelp,
  FileQuestion,
  Files,
  MessageCircleMore,
  SquareTerminal,
  FileCheck2,
  FolderSearch,
  Vault,
} from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  useSidebar,
  SidebarMenuItem,
  SidebarMenu,
  SidebarMenuButton,
  SidebarGroupLabel,
  SidebarMenuBadge,
} from '@/components/ui/sidebar';
import { IconLogo, Logo, ImageEscrow, ImageBackups, ImageCustodian, ImageSaasEscrow } from 'src/components/Svg/Images';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu';

import { NavUser } from './ui';

const IconHome = () => (
  <span className="flex items-center justify-center w-8 h-8 p-2 bg-secondary rounded-lg flex-shrink-0">
    <Home className="w-100 h-100 text-white" />
  </span>
);

const mainItems = [
  {
    title: 'Home',
    url: '/',
    icon: IconHome,
  },
  {
    title: 'Software Backup',
    url: '/backups',
    icon: ImageBackups,
  },
  {
    title: 'Software Escrow',
    url: '/escrows',
    icon: ImageEscrow,
  },
  {
    title: 'SaaS Escrow',
    url: '/saas-escrows',
    icon: ImageSaasEscrow,
  },
  {
    title: 'Software Custodian',
    url: '/custodian-profiles',
    icon: ImageCustodian,
  },
];

type Props = React.ComponentProps<typeof Sidebar> & {
  withBilling?: boolean;
  user: {
    name: string;
    email: string;
  };
  migrationsCount: number;
};

export function AppSidebar({ withBilling, user, migrationsCount, ...props }: Props) {
  const { pathname } = useLocation();
  const { open } = useSidebar();

  const footerItems = [
    {
      icon: LayoutGrid,
      label: 'Integrations',
      items: [
        { icon: LayoutGrid, label: 'Integrations', to: '/integrations' },
        { icon: FileQuestion, label: 'Explore & FAQs', to: 'https://codekeeper.co/integrations', target: '_blank' },
      ],
    },
    {
      icon: CircleHelp,
      label: 'Support',
      items: [
        { icon: MessageCircleMore, label: 'Support', to: 'https://codekeeper.co/support', target: '_blank' },
        { icon: Files, label: 'Documentation', to: 'https://codekeeper.co/how-it-works', target: '_blank' },
        { icon: FileCheck2, label: 'Verification', to: 'https://codekeeper.co/verification', target: '_blank' },
        { icon: FolderSearch, label: 'Resources', to: 'https://codekeeper.co/resources', target: '_blank' },
      ],
    },
    {
      icon: Settings,
      label: 'Settings',
      items: [
        { icon: LayoutList, label: 'Organization', to: '/account' },
        ...(withBilling ? [{ icon: WalletCards, label: 'Billing', to: '/billing' }] : []),
      ],
    },
  ];

  return (
    <Sidebar {...props} collapsible="icon">
      <SidebarHeader>
        <Link to="/">{open ? <Logo className="w-[130px] h-[40px]" /> : <IconLogo className="w-8 h-8" />}</Link>
      </SidebarHeader>
      <SidebarContent className="p-2">
        <SidebarMenu className="gap-1 group-data-[collapsible=icon]:gap-2">
          {mainItems.map((item) => (
            <SidebarMenuItem key={item.title}>
              <SidebarMenuButton
                isActive={item.url === pathname}
                className="py-2 px-2 h-12"
                asChild
                tooltip={item.title}
              >
                <Link
                  to={item.url}
                  className="flex items-center gap-2 group-data-[collapsible=icon]:group-[&:not(:first-child)]/menu-item:!p-1 group-data-[collapsible=icon]:group-first/menu-item:!p-0"
                >
                  {open ? (
                    <span className="flex items-center justify-center w-8 h-8 p-1">
                      <item.icon className="w-100 h-100" />
                    </span>
                  ) : (
                    <item.icon className="!w-6 !h-6" />
                  )}
                  <span className="text-primary font-semibold">{item.title}</span>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          ))}
        </SidebarMenu>
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu className="text-primary data-[menu-button]:hover:text-primary">
          <SidebarMenuItem>
            <SidebarMenuButton asChild isActive={pathname === '/notifications'} tooltip="Notifications">
              <Link to="/deposits">
                <Vault /> Deposits
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
          {Boolean(migrationsCount) && (
            <SidebarMenuItem>
              <SidebarMenuButton asChild isActive={pathname === '/migrations'} tooltip="Migrations">
                <Link to="/migrations">
                  <SquareTerminal /> Migrations
                  <SidebarMenuBadge>{migrationsCount}</SidebarMenuBadge>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          )}
          {footerItems.map((menu) => (
            <SidebarMenuItem key={menu.label}>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <SidebarMenuButton tooltip={menu.label}>
                    <menu.icon /> {menu.label}
                    <ChevronRight className="ml-auto" />
                  </SidebarMenuButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent side="right" align="start" className="w-max">
                  <SidebarGroupLabel className="h-max py-1">{menu.label}</SidebarGroupLabel>
                  {menu.items.map((item) => (
                    <DropdownMenuItem key={item.label} asChild className="py-2">
                      <Link to={item.to} target={item.target} className="cursor-pointer">
                        <item.icon />
                        <span>{item.label}</span>
                      </Link>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </SidebarMenuItem>
          ))}
        </SidebarMenu>
        <NavUser user={user} />
      </SidebarFooter>
    </Sidebar>
  );
}
