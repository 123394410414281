import React from 'react';

import { JourneyMenu } from 'src/components/Journey/Menu';

import EscrowMenuItem, { IEscrowMenuItem } from './EscrowMenuItem';
import styles from './EscrowMenu.module.scss';

type Props = {
  basePath: string;
  escrowId: string;
  isLoading?: boolean;
  items: IEscrowMenuItem[];
};

const EscrowMenu: React.FunctionComponent<Props> = ({ items, escrowId, ...rest }) => {
  return (
    <JourneyMenu className={styles.nav}>
      {items.map((item) => (
        <EscrowMenuItem key={item.path} slug={escrowId} {...item} {...rest} />
      ))}
    </JourneyMenu>
  );
};

export default EscrowMenu;
