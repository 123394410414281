export const notificationTypeMap = {
  Escrow: {
    bgBadge: 'bg-secondary',
    badge: 'Software Escrow',
    baseUrl: '/escrows',
  },
  SaasEnvironment: {
    bgBadge: 'bg-accent-saas-escrow',
    badge: 'SaaS Escrow',
    baseUrl: '/saas-escrows',
  },
  CustodianProfile: {
    bgBadge: 'bg-accent-software-custodian',
    badge: 'Software Custodian',
    baseUrl: '/custodian-profiles',
  },
};
