import { useState } from 'react';
import { useNavigate } from 'react-router';

import { TwoFAForm, ITwoFAForm } from 'src/components/Authentication';
import { TextBlock, message } from 'src/components/Misc';
import styles from 'src/components/Authentication/Authentication.module.scss';
import { useAuth } from 'src/context/auth/use-auth';

const TwoFAContainer = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { confirmTOTPCode } = useAuth();
  const navigate = useNavigate();

  const onSubmit = async ({ code }: ITwoFAForm) => {
    setIsLoading(true);

    try {
      await confirmTOTPCode(code);
      navigate('/');
    } catch (e: unknown) {
      if (e instanceof Error) {
        message.error(e.message);
        if (e.name === 'SignInException') navigate('/login');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.card}>
      <TextBlock
        title="Enter 6-digit code"
        text="Enter the code you see in your authenticator app."
        className={styles['two-fa__text-block']}
      />
      <TwoFAForm onSubmit={onSubmit} isLoading={isLoading} />
    </div>
  );
};

export default TwoFAContainer;
