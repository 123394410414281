import { Navigate } from 'react-router';

import { useAuth } from 'src/context/auth/use-auth';

const UnprotectedRoute = ({ children }: React.PropsWithChildren) => {
  const { inProgress, isSignedIn } = useAuth();

  console.log(`UnprotectedRoute: ${inProgress}`);

  if (isSignedIn) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default UnprotectedRoute;
