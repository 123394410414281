import { Outlet, useNavigate } from 'react-router';

import { EscrowMenu } from 'src/components/Escrow/Menu';
import { EscrowStatusIndicator, EscrowStatusIndicatorEnum } from 'src/components/Escrow/StatusIndicator';
import { escrowMenuItems } from 'src/components/Escrow/Menu/escrowMenu.enum';
import { JourneyNavigation } from 'src/components/Journey/Navigation';
import { JourneyModalLayout, JourneyModalHeader } from 'src/components/Journey/ModalLayout';

const EscrowNewModalLayoutContainer = () => {
  const navigate = useNavigate();

  const onClose = () => navigate('/escrows');

  return (
    <JourneyModalLayout
      header={<JourneyModalHeader title="New Escrow" onClose={onClose} />}
      statusIndicator={<EscrowStatusIndicator currentStatus={EscrowStatusIndicatorEnum.CONFIG} />}
      menu={
        <EscrowMenu
          basePath="escrows"
          items={escrowMenuItems.map((item, index) => (index === 0 ? { ...item, path: '' } : item))}
          escrowId="new"
        />
      }
    >
      <Outlet />
      <JourneyNavigation isNextDisabled isPrevHidden />
    </JourneyModalLayout>
  );
};

export default EscrowNewModalLayoutContainer;
