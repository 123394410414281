import { useState } from 'react';
import { debounce } from 'lodash';

import { message } from 'src/components/Misc';
import {
  useAssetsQuery,
  useAssetsLazyQuery,
  QueryOrderEnum,
  AssetsOrderByEnum,
  IAssetsQueryVariables,
} from 'src/graphql/schema/index.ts';
import useSorting from 'src/hooks/use-sorting';
import Loader from 'src/components/Loader/Loader';
import { Main } from 'src/components/Layout';
import { DEPOSITS_PER_PAGE } from 'src/shared/constants';
import { AddDepositContextProvider } from 'src/context/deposit';
import { usePageMeta } from 'src/hooks';

import type { IAssetNodes } from './ui';

import { ActiveDeposits, EmptyDeposits, MakeDeposit } from './ui';

const MakeDepositAction = ({ refetch }: { refetch: () => void }) => (
  <AddDepositContextProvider refetch={refetch} softwareType="escrow">
    <MakeDeposit />
  </AddDepositContextProvider>
);

const Deposits: React.FunctionComponent = () => {
  usePageMeta('Deposits');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentAssets, setCurrentAssets] = useState<IAssetNodes>([]);
  const [currentTotalAssets, setCurrentTotalAssets] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useSorting();
  const [filter, setFilter] = useState<AssetsOrderByEnum | null>(null);

  const { loading, data, refetch } = useAssetsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      perPage: DEPOSITS_PER_PAGE,
      page: 1,
      order: QueryOrderEnum.Desc,
    },
    onCompleted: ({ assets }) => {
      const assetsNodes = assets?.nodes;
      const total = assets?.nodesCount;

      setCurrentAssets(assetsNodes);
      setCurrentTotalAssets(total);
    },
  });

  const [getAssets, { loading: lazyLoading }] = useAssetsLazyQuery({
    onCompleted: ({ assets }) => {
      const assetsNodes = assets?.nodes;
      const total = assets?.nodesCount;

      setCurrentAssets(assetsNodes);
      setCurrentTotalAssets(total);
    },
  });

  const assets = data?.assets.nodes ?? [];

  const getCurrentPage = async (value: number) => {
    setCurrentPage(value);

    await getAssets({
      variables: {
        page: value,
        perPage: DEPOSITS_PER_PAGE,
        queryFilter: searchQuery,
        order,
      },
    });
  };

  const onSearchChange = debounce(async (value: string) => {
    try {
      setSearchQuery(value);

      await getAssets({
        variables: {
          page: 1,
          perPage: DEPOSITS_PER_PAGE,
          queryFilter: value,
          order,
        },
      });
    } catch (error) {
      message.error('Something went wrong.');
    }
  }, 500);

  const onOrderChange = async () => {
    let currentOrder = order;

    if (currentOrder === 'asc') {
      currentOrder = QueryOrderEnum.Desc;
    } else {
      currentOrder = QueryOrderEnum.Asc;
    }

    setOrder();

    await getAssets({
      variables: {
        page: currentPage,
        perPage: DEPOSITS_PER_PAGE,
        queryFilter: searchQuery,
        order: currentOrder,
      },
    });
  };

  const onFilterChange = async (value: AssetsOrderByEnum | null) => {
    setFilter(value);

    const variables: IAssetsQueryVariables = {
      page: currentPage,
      perPage: DEPOSITS_PER_PAGE,
      queryFilter: searchQuery,
      order,
      orderBy: value,
    };

    if (!value) delete variables.orderBy;

    await getAssets({
      variables,
    });
  };

  if (loading) return <Loader />;

  return (
    <Main title="Deposits" headerActions={<MakeDepositAction refetch={refetch} />} className="deposit">
      {!assets.length ? (
        <EmptyDeposits>
          <MakeDepositAction refetch={refetch} />
        </EmptyDeposits>
      ) : (
        <ActiveDeposits
          assets={currentAssets}
          getCurrentPage={getCurrentPage}
          currentPage={currentPage}
          nodesCount={currentTotalAssets}
          loading={lazyLoading}
          order={order}
          filter={filter}
          onOrderChange={onOrderChange}
          onFilterChange={onFilterChange}
          onSearchChange={onSearchChange}
        />
      )}
    </Main>
  );
};

export default Deposits;
