import { Link } from 'react-router';

import { LabelText } from 'src/components/Label';
import { renderLogo, logoMap } from 'src/utils/renderLogo';

import { integrationLabelStatusEnum as statusTypeEnum } from '../../backup.enum';

import type { BackupStatusEnum } from 'src/graphql/schema';

import BackupItemDropdown from './BackupItemDropdown';
import styles from './BackupItem.module.scss';

export type IBackupItem = {
  id: string;
  status: BackupStatusEnum;
  integration: {
    accountName: string;
    provider: string;
  };
  assetsCount: number;
  canRemove: boolean;
};

type Props = {
  data: IBackupItem;
  dropdownItemClick: (value: string) => void;
};

const BackupItem = ({ data, dropdownItemClick }: Props) => {
  const { id, status, assetsCount, integration, canRemove } = data;

  const statusType: (typeof statusTypeEnum)[keyof typeof statusTypeEnum] | undefined =
    status && status in statusTypeEnum ? statusTypeEnum[status] : undefined;
  const Icon = renderLogo(integration.provider as keyof typeof logoMap);

  return (
    <div className={styles.item}>
      <Link to={`/backups/${id}/platform`}>
        <div className={styles['item-wrapper']}>
          <div className={styles['item-action']}>
            <BackupItemDropdown onClick={dropdownItemClick} canRemove={canRemove} />
          </div>
          <div className={styles['item-row']}>
            <figure className={styles.figure}>{Icon && <Icon />}</figure>
            <div className={styles.content}>
              <div className={styles.name}>{integration.accountName}</div>
            </div>
          </div>

          {statusType && <LabelText {...statusType} className={styles['integration-status']} />}

          <div className={styles['info-row']}>
            <span className={styles['info-label']}>Assets:</span>{' '}
            <strong className={styles['info-value']}>{assetsCount}</strong>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BackupItem;
