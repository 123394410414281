import { useNavigate, useParams } from 'react-router';

import { JourneyNavigation } from 'src/components/Journey/Navigation';
import { SaasRoutesEnum } from 'src/pages/Saas';
import { useSaasEscrow } from 'src/context/saas';
import { SaasEnvironmentStatusEnum } from 'src/graphql/schema';

const SaasNavigationContainer = () => {
  const navigate = useNavigate();
  const { id, '*': activePath } = useParams();
  const { environment } = useSaasEscrow();

  const routes = [SaasRoutesEnum.Providers];

  if (environment.status === SaasEnvironmentStatusEnum.Active) {
    routes.push(SaasRoutesEnum.Certificate);
  }

  const activeRouteIndex = routes.findIndex((route) => route === activePath);

  const goToPreviousRoute = () => {
    if (activeRouteIndex > 0) {
      navigate(`/saas-escrows/${id}/${routes[activeRouteIndex - 1]}`);
    }
  };

  const goToNextRoute = () => {
    if (activeRouteIndex < routes.length - 1) {
      navigate(`/saas-escrows/${id}/${routes[activeRouteIndex + 1]}`);
    }
  };

  return (
    <JourneyNavigation
      isPrevHidden={!activeRouteIndex}
      isNextHidden={activeRouteIndex === routes.length - 1}
      onPrev={goToPreviousRoute}
      onNext={goToNextRoute}
    />
  );
};

export default SaasNavigationContainer;
