import { useState, Fragment } from 'react';
import { get, debounce } from 'lodash';
import { useSearchParams } from 'react-router';

import { message, ModalProceed } from 'src/components/Misc';
import {
  useEscrowsQuery,
  EscrowStatusFilterEnum,
  QueryOrderEnum,
  useRemoveEscrowMutation,
  EscrowsDocument,
} from 'src/graphql/schema';
import useSorting from 'src/hooks/use-sorting';
import { EscrowsTabs, EscrowsTabContent, IEscrowTableRow } from 'src/components/Escrows';
import { ESCROWS_LIMIT } from 'src/shared/constants';

import { mapEscrows } from './lib';
import EscrowTableActionsContainer from './EscrowTableActionsContainer';

const EscrowsContainer: React.FunctionComponent = () => {
  const [currentEscrows, setCurrentEscrows] = useState<IEscrowTableRow[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [escrowId, setEscrowId] = useState<string>('');

  const [order, setOrder] = useSorting();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabParams = (searchParams.get('t') || 'all') as EscrowStatusFilterEnum;
  const activeTabKey = Object.values(EscrowStatusFilterEnum).includes(tabParams) ? tabParams : 'all';

  const [removeEscrow, { loading: loadingRemove }] = useRemoveEscrowMutation({
    refetchQueries: [EscrowsDocument],
  });

  const { loading, data } = useEscrowsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      page: Number(searchParams.get('page')) || 1,
      perPage: ESCROWS_LIMIT,
      order: order as QueryOrderEnum,
      statusFilter: activeTabKey as EscrowStatusFilterEnum,
      queryFilter: searchQuery,
      actionableItems: true,
    },
    onCompleted: ({ escrows }) => {
      setCurrentEscrows(mapEscrows(escrows.nodes));
    },
    onError: () => message.error('Something went wrong'),
  });

  const showEmptyState = !data || (!searchQuery && !currentEscrows.length);

  const onSearchChange = debounce(async (value: string) => {
    setSearchParams({ t: searchParams.get('t') || '', page: '1' });
    setSearchQuery(value);
  }, 500);

  const onTabChange = (key: string) => {
    setSearchParams({ t: key });
    setSearchQuery('');
  };

  const onPageChange = async (page: number) => {
    setSearchParams({ t: searchParams.get('t') || '', page: String(page) });
  };

  const deleteEscrow = async () => {
    try {
      const { data } = await removeEscrow({
        variables: {
          escrowId,
        },
      });
      if (data?.removeEscrow?.success) {
        message.success('Escrow removed!');
        setEscrowId('');
      } else {
        throw new Error(data?.removeEscrow?.errors?.[0]);
      }
    } catch (error: unknown) {
      if (error instanceof Error) message.error(error.message || 'Something went wrong');
    }
  };

  return (
    <Fragment>
      <EscrowsTabs
        onTabChange={onTabChange}
        activeTabKey={activeTabKey}
        isLoading={loading}
        showEmptyState={showEmptyState}
      >
        <EscrowsTabContent
          isLoading={loading}
          order={order}
          onOrderChange={setOrder}
          onSearchChange={onSearchChange}
          data={currentEscrows}
          pagination={{
            total: get(data, 'escrows.nodesCount', 0),
            current: Number(searchParams.get('page')) || 1,
            onChange: onPageChange,
          }}
          actions={(props) => <EscrowTableActionsContainer {...props} onRemove={setEscrowId} />}
        />
      </EscrowsTabs>

      <ModalProceed
        isOpen={Boolean(escrowId)}
        onProceed={deleteEscrow}
        onCancel={() => setEscrowId('')}
        title="Delete escrow"
        text="Are you sure you want to delete this draft? All the configured data in this escrow will be deleted."
        proceedText="Delete"
        proceedButtonProps={{ danger: true, type: 'default' }}
        isLoading={loadingRemove}
      />
    </Fragment>
  );
};

export default EscrowsContainer;
