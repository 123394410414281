import { useParams } from 'react-router';

import { AlertActionMessage } from 'src/components/Alert';
import { alertMessagesTypeEnum } from 'src/components/Backup/backup.enum';
import { useBackup } from 'src/context/backup';
import { BackupRoutesEnum } from 'src/pages/Backup';
import { BackupStatusEnum } from 'src/graphql/schema';

const BackupAlertBannerContainer = () => {
  const { '*': activePath } = useParams<{
    '*': BackupRoutesEnum;
  }>();
  const { backup } = useBackup();

  const backupStatus = backup.status;
  let alertMessage:
    | ({ color?: string } & (typeof alertMessagesTypeEnum)[keyof typeof alertMessagesTypeEnum])
    | undefined =
    backupStatus && backupStatus in alertMessagesTypeEnum ? alertMessagesTypeEnum[backupStatus] : undefined;

  // Show message if there are no members, owner is added by default
  if (
    activePath === BackupRoutesEnum.Members &&
    (backupStatus === BackupStatusEnum.Draft ||
      (backupStatus === BackupStatusEnum.Active && !(backup.backupMembers.length - 1)))
  ) {
    alertMessage = alertMessagesTypeEnum.members;
  }

  // Show message if there are no assets
  if (
    activePath === BackupRoutesEnum.Assets &&
    (backupStatus === BackupStatusEnum.Draft ||
      (backupStatus === BackupStatusEnum.Active && !backup.backupAssets.length))
  ) {
    alertMessage = alertMessagesTypeEnum.assets;
  }

  if (!alertMessage) return null;

  return (
    <AlertActionMessage
      iconType={alertMessage.iconType}
      bgColor={alertMessage.bgColor}
      text={alertMessage.text}
      color={alertMessage.color}
    />
  );
};

export default BackupAlertBannerContainer;
