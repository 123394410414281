import { useSuspenseQuery } from '@apollo/client';

import { AwaitingLegacyReposCountDocument } from 'src/graphql/schema';
import { MigrationAlert } from 'src/components/Migration';
import { withSuspense } from 'src/hoc';

import type { IAwaitingLegacyReposCountQuery } from 'src/graphql/schema';

const MigrationAlertContainer: React.FunctionComponent = () => {
  const { data } = useSuspenseQuery<IAwaitingLegacyReposCountQuery>(AwaitingLegacyReposCountDocument);

  const nodesCount = data?.legacyRepos.count ?? 0;

  if (!nodesCount) return null;

  return <MigrationAlert nodesCount={nodesCount} />;
};

export default withSuspense(MigrationAlertContainer, () => <div />);
