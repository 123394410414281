import { redirect } from 'react-router-dom';

import {
  SaasEscrows,
  SaasEnvironments,
  saasRouteElements,
  SaasRoutesEnum,
  SaasEnvironmentLayout,
} from 'src/pages/Saas';

import type { RouteObject } from 'react-router';

type InferPath<T> = T extends { path: infer P extends string } ? { path: `saas-${P}` } : never;

export const saasEnvironmentsRoute: RouteObject & InferPath<{ path: 'environments' }> = {
  path: 'saas-environments',
  element: <SaasEnvironments />,
};

export const saasEscrowsRoutes: RouteObject & InferPath<{ path: 'escrows' }> = {
  path: 'saas-escrows',
  element: <SaasEscrows />,
};

export const saasSingleRoutes: RouteObject = {
  element: <SaasEnvironmentLayout />,
  path: 'saas-escrows',
  children: [
    {
      loader: ({ params }) => {
        const { id = null, '*': splat = '' } = params;

        if (id && !splat) return redirect(`/saas-escrows/${id}/${SaasRoutesEnum.Providers}`);

        const path = splat?.split('/').length > 1 ? splat?.split('/')[0] : splat;

        if (!Object.values(SaasRoutesEnum).includes(path as SaasRoutesEnum)) {
          return redirect('/404');
        }

        return null;
      },
      path: ':id/*',
      children: saasRouteElements,
    },
  ],
};
