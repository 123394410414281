import { useNavigate } from 'react-router';

import { JourneyHeader } from 'src/components/Journey';
import { useBackup } from 'src/context/backup';
import { integrationLabelStatusEnum as statusTypeEnum } from 'src/components/Backup/backup.enum';

const BackupHeaderContainer = () => {
  const navigate = useNavigate();
  const { backup } = useBackup();

  const onBack = () => navigate('/backups');

  const statusType: (typeof statusTypeEnum)[keyof typeof statusTypeEnum] | undefined =
    backup.status in statusTypeEnum ? statusTypeEnum[backup.status] : undefined;

  return (
    <JourneyHeader
      breadcrumbItems={[
        {
          path: 'backups',
          title: 'Backups',
        },
        {
          title: backup.integration.accountName,
        },
      ]}
      onBack={onBack}
      statusLabel={statusType}
    />
  );
};

export default BackupHeaderContainer;
