import { useSuspenseQuery } from '@apollo/client';

import { ProfileDocument, IsUpdateableBillingDocument, AwaitingLegacyReposCountDocument } from 'src/graphql/schema';
import { AppSidebar, SidebarSkeleton } from 'src/components/Layout/AppSidebar';
import { withSuspense } from 'src/hoc/withSuspense';

import type { IProfileQuery, IIsUpdateableBillingQuery, IAwaitingLegacyReposCountQuery } from 'src/graphql/schema';

const SidebarContainer: React.FunctionComponent = () => {
  const { data: legacyReposCount } = useSuspenseQuery<IAwaitingLegacyReposCountQuery>(AwaitingLegacyReposCountDocument);
  const { data: updateableBillingData } = useSuspenseQuery<IIsUpdateableBillingQuery>(IsUpdateableBillingDocument);
  const { data: profileData } = useSuspenseQuery<IProfileQuery>(ProfileDocument);

  const withBilling = Boolean(updateableBillingData?.company.updateableBilling);
  const nodesCount = legacyReposCount?.legacyRepos.count ?? 0;

  return (
    <AppSidebar
      migrationsCount={nodesCount}
      withBilling={withBilling}
      user={{
        name: profileData.profile.name ?? '',
        email: profileData.profile.email ?? '',
      }}
    />
  );
};

export default withSuspense(SidebarContainer, SidebarSkeleton);
