import { NotificationContainer } from 'src/containers/Notification';
import { usePageMeta } from 'src/hooks';

const ProfileActivityContainer = () => {
  usePageMeta('Activity');

  return (
    <div className="rounded-lg bg-card p-6 border border-border shadow-sm">
      <h3 className="text-base font-bold text-foreground mb-4">Latest in your Organization</h3>
      <NotificationContainer interacted={null} withActionColumn={false} />
    </div>
  );
};

export default ProfileActivityContainer;
