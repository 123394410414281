import { useNavigate } from 'react-router';

import { useSaasEscrow } from 'src/context/saas';
import { JourneyHeader } from 'src/components/Journey';
import { labelStatusTypeEnum } from 'src/components/Saas/Escrows';

const SaasHeaderContainer = () => {
  const navigate = useNavigate();
  const { environment } = useSaasEscrow();

  const onBack = () => navigate('/saas-escrows');

  let statusLabel: (typeof labelStatusTypeEnum)[keyof typeof labelStatusTypeEnum] | undefined =
    environment.status in labelStatusTypeEnum
      ? labelStatusTypeEnum[environment.status as keyof typeof labelStatusTypeEnum]
      : undefined;

  if (environment.saasEnvironmentTermination) statusLabel = labelStatusTypeEnum['terminated-requested'];

  return (
    <JourneyHeader
      breadcrumbItems={[
        {
          path: 'saas-escrows',
          title: 'SaaS Escrow',
        },
        {
          title: environment?.name,
        },
      ]}
      statusLabel={statusLabel}
      onBack={onBack}
    />
  );
};

export default SaasHeaderContainer;
