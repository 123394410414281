import { Fragment } from 'react';

import { ActionCardSkeleton, HomeBannerSkeleton } from './';

const HomeSkeleton = () => {
  return (
    <Fragment>
      <div className="flex gap-4 mb-6">
        <div className="grow">
          <HomeBannerSkeleton />
        </div>
        <div className="w-[232px]">
          <ActionCardSkeleton />
        </div>
      </div>

      <div className="h-[1px] w-full bg-border mb-6" />

      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
        {Array.from({ length: 4 }).map((_, index) => (
          <ActionCardSkeleton key={index} />
        ))}
      </div>
    </Fragment>
  );
};

export default HomeSkeleton;
