import { useBackgroundQuery } from '@apollo/client';
import { Navigate } from 'react-router';

import { Main } from 'src/components/Layout';
import { MigrationsContextProvider, useMigrations } from 'src/context/migrations';
import { MigrationsTableContainer, MigrationsConnectContainer } from 'src/containers/Migrations';
import { IntegrationTypeEnum, IntegrationsDocument } from 'src/graphql/schema';
import { usePageMeta } from 'src/hooks';

import type { IIntegrationsQuery } from 'src/graphql/schema';

import InfoModal from './ui/InfoModal';
import MigrationsPageAlert from './ui/MigrationsPageAlert';

const MigrationsMain = () => {
  usePageMeta('Migrations');
  const [queryRef] = useBackgroundQuery<IIntegrationsQuery>(IntegrationsDocument, {
    variables: {
      active: true,
      type: IntegrationTypeEnum.Oauth,
      perPage: 100,
    },
  });
  const { legacy } = useMigrations();

  if (legacy.count === 0) return <Navigate to="/" />;

  return (
    <Main title="Migrations" className="ck-migrations-page" headerChildren={<MigrationsPageAlert />}>
      <MigrationsTableContainer queryRef={queryRef} />

      <InfoModal />
      <MigrationsConnectContainer queryRef={queryRef} />
    </Main>
  );
};

const Migrations = () => {
  return (
    <MigrationsContextProvider>
      <MigrationsMain />
    </MigrationsContextProvider>
  );
};

export default Migrations;
