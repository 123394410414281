import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import * as Auth from '@aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import * as Sentry from '@sentry/react';

import env from 'env';

import { createRequiredContext } from '../createRequiredContext';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: env.VITE_COGNITO_USER_POOL_ID,
      userPoolClientId: env.VITE_COGNITO_USER_POOL_WEB_CLIENT_ID,
    },
  },
});

type CognitoUser = Auth.AuthUser;

interface IAuthContext {
  isSignedIn: boolean;
  inProgress: boolean;
  signIn: (email: string, password: string) => Promise<{ isSignedIn: boolean; newPasswordRequired: boolean }>;
  signOut: () => void;
  changeEmail: (email: string) => Promise<Auth.UpdateUserAttributesOutput>;
  setNewPassword: (password: string) => Promise<Auth.ConfirmSignInOutput>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  sendPasswordResetEmail: (email: string) => Promise<Auth.ResetPasswordOutput>;
  confirmPasswordReset: (email: string, code: string, password: string) => Promise<void>;
  setUpTOTP: () => Promise<URL>;
  verifyTOTPSetup: (totpCode: string) => Promise<void>;
  shouldShowTOTPType: () => Promise<boolean>;
  updateTOTP: (value: boolean) => Promise<void>;
  confirmTOTPCode: (code: string) => Promise<Auth.ConfirmSignInOutput>;
}

const [useAuth, AuthContextProvider] = createRequiredContext<IAuthContext>();

const authStrategy = env.VITE_COGNITO_AUTH ? useCognitoAuth : useLocalAuth;

function AuthProvider({ children }: { children: React.ReactNode }) {
  const auth = authStrategy();

  return <AuthContextProvider value={auth}>{children}</AuthContextProvider>;
}

export { useAuth, AuthProvider };

function useCognitoAuth(): IAuthContext {
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [inProgress, setInProgress] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const setSentryEmail = async () => {
      if (!user) return;

      try {
        const email = (await Auth.fetchUserAttributes()).email;

        if (!email) return;

        Sentry.setUser({ email });
      } catch (e: unknown) {
        if (e instanceof Error) {
          if (e.name === 'NotAuthorizedException' || e.name === 'UserNotFoundException') return signOut();
        }
      }
    };

    setSentryEmail();
  }, [user]);

  const signIn: IAuthContext['signIn'] = async (email, password) => {
    const { nextStep } = await Auth.signIn({
      username: email,
      password,
    });

    switch (nextStep.signInStep) {
      case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED': {
        navigate('/set-password');

        return { isSignedIn: false, newPasswordRequired: true };
      }
      case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE': {
        navigate('/auth/2fa');

        return { isSignedIn: false, newPasswordRequired: false };
      }
      case 'DONE': {
        navigate('/');
        setIsSignedIn(true);

        return { isSignedIn: true, newPasswordRequired: false };
      }
      default:
        throw new Error('Unknown sign in step');
    }
  };

  const signOut: IAuthContext['signOut'] = async () => {
    await Auth.signOut();

    setUser(null);
    setIsSignedIn(false);
    window.location.replace('/login');
  };

  const changeEmail: IAuthContext['changeEmail'] = (email) =>
    Auth.updateUserAttributes({
      userAttributes: { email },
    });

  const setNewPassword: IAuthContext['setNewPassword'] = async (password: string) => {
    const data = await Auth.confirmSignIn({ challengeResponse: password });
    setIsSignedIn(true);

    return data;
  };

  const changePassword: IAuthContext['changePassword'] = (oldPassword, newPassword) =>
    Auth.updatePassword({ oldPassword, newPassword });

  const sendPasswordResetEmail: IAuthContext['sendPasswordResetEmail'] = (email) =>
    Auth.resetPassword({ username: email });

  const confirmPasswordReset: IAuthContext['confirmPasswordReset'] = (email, code, password) =>
    Auth.confirmResetPassword({ username: email, confirmationCode: code, newPassword: password });

  // MFA - TOTP
  const setUpTOTP = async () => {
    const { getSetupUri } = await Auth.setUpTOTP();
    const setupUri = getSetupUri('codekeeper-app');

    return setupUri;
  };

  const verifyTOTPSetup = async (totpCode: string) => {
    try {
      return await Auth.verifyTOTPSetup({ code: totpCode });
    } catch (e: unknown) {
      const error = e as Error;

      if (error.message.includes("Value at 'userCode' failed to satisfy constraint")) {
        throw new Error('Incorrect code entered.');
      } else throw e;
    }
  };

  const updateTOTP = (value: boolean) =>
    Auth.updateMFAPreference({
      totp: value ? 'ENABLED' : 'DISABLED',
    });

  const shouldShowTOTPType = async () => {
    const { enabled } = await Auth.fetchMFAPreference();

    return Array.isArray(enabled) && enabled.includes('TOTP');
  };

  const confirmTOTPCode = async (code: string) => {
    const data = await Auth.confirmSignIn({ challengeResponse: code });
    setIsSignedIn(true);

    return data;
  };

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const user = await Auth.getCurrentUser();
        setUser(user);
        setIsSignedIn(true);
      } catch {
        setUser(null);
        setIsSignedIn(false);
      } finally {
        setInProgress(false);
      }
    };

    fetchCurrentUser();
  }, []);

  return {
    isSignedIn,
    inProgress,
    signIn,
    signOut,
    changeEmail,
    setNewPassword,
    changePassword,
    sendPasswordResetEmail,
    confirmPasswordReset,
    setUpTOTP,
    verifyTOTPSetup,
    shouldShowTOTPType,
    updateTOTP,
    confirmTOTPCode,
  };
}

function useLocalAuth(): IAuthContext {
  const [, setUser] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [inProgress, setInProgress] = useState(true);

  const signIn = async (email: string, password: string) => {
    const response = await fetch(`${env.VITE_BACKEND_URL}/local-auth`, {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const body = await response.json();

    if (!response.ok) {
      throw new Error(body.message);
    }

    if (body.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return { isSignedIn: false, newPasswordRequired: true };
    } else {
      localStorage.setItem('user', JSON.stringify(body));
      setUser(body);
      setIsSignedIn(true);

      return { isSignedIn: true, newPasswordRequired: false };
    }
  };

  const signOut = () => {
    localStorage.removeItem('user');
    setUser(null);
    setIsSignedIn(false);

    window.location.replace('/login');
  };

  const changeEmail: IAuthContext['changeEmail'] = () => {
    throw new Error('Not implemented locally!');
  };
  const setNewPassword: IAuthContext['setNewPassword'] = () => {
    throw new Error('Not implemented locally!');
  };
  const changePassword: IAuthContext['changePassword'] = () => Promise.resolve();
  const sendPasswordResetEmail: IAuthContext['sendPasswordResetEmail'] = () => {
    throw new Error('Not implemented locally!');
  };
  const confirmPasswordReset: IAuthContext['confirmPasswordReset'] = () => Promise.resolve();

  useEffect(() => {
    const user = localStorage.getItem('user');

    if (user) {
      setUser(JSON.parse(user));
      setIsSignedIn(true);
    } else {
      setUser(null);
      setIsSignedIn(false);
    }

    setInProgress(false);
  }, []);

  return {
    isSignedIn,
    inProgress,
    signIn,
    signOut,
    changeEmail,
    setNewPassword,
    changePassword,
    sendPasswordResetEmail,
    confirmPasswordReset,
    setUpTOTP: async () => new URL(''),
    verifyTOTPSetup: async () => {},
    shouldShowTOTPType: async () => false,
    updateTOTP: async () => {},
    confirmTOTPCode: async () => ({
      isSignedIn: true,
      nextStep: {
        signInStep: 'DONE',
      },
    }),
  };
}
