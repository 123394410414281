import { LazyLoad } from 'src/components/Misc';
const CustodianAssetsContainer = () => (
  <LazyLoad loader={() => import('src/containers/Custodian/Assets/CustodianAssetsContainer')} />
);
const CustodianOperationsContainer = () => (
  <LazyLoad loader={() => import('src/containers/Custodian/Operations/CustodianOperationsContainer')} />
);
const CustodianIntegrationsContainer = () => (
  <LazyLoad loader={() => import('src/containers/Custodian/Integrations/CustodianIntegrationsContainer')} />
);
const CustodianCertificateContainer = () => (
  <LazyLoad loader={() => import('src/containers/Custodian/Certificate/CustodianCertificateContainer')} />
);

import type { RouteObject } from 'react-router';

export enum CustodianRoutesEnum {
  Assets = 'assets',
  Operations = 'operations',
  Integrations = 'integrations',
  Certificate = 'certificate',
}

export const custodianRouteElements: RouteObject[] = [
  {
    path: CustodianRoutesEnum.Assets,
    element: <CustodianAssetsContainer />,
  },
  {
    path: CustodianRoutesEnum.Operations,
    element: <CustodianOperationsContainer />,
  },
  {
    path: CustodianRoutesEnum.Integrations,
    element: <CustodianIntegrationsContainer />,
  },
  {
    path: CustodianRoutesEnum.Certificate,
    element: <CustodianCertificateContainer />,
  },
];
