import { useParams } from 'react-router';
import { cloneDeep } from '@apollo/client/utilities';

import { EscrowMenu } from 'src/components/Escrow/Menu';
import { escrowMenuItems } from 'src/components/Escrow/Menu/escrowMenu.enum';
import { useEscrow } from 'src/context/escrow';
import { EscrowRoutesEnum } from 'src/pages/Escrow';
import { useClientChangesRequestedQuery, EscrowStatusEnum, EscrowPartyTypeEnum } from 'src/graphql/schema';

import type { IEscrowMenuItem } from 'src/components/Escrow/Menu';

import { applySuggestedChangesToMenuItems, updateMenuItemsForDraftOrAdminReview } from './lib';
import { withSuggestedChangesApi } from './model';

const EscrowMenuContainer = () => {
  const {
    escrow,
    escrow: { agreementSettingsConfigured, depositorConfigured, beneficiaryConfigured },
    ui: {
      isSuggestChangesMode,
      members: { isEditAccessMode },
    },
  } = useEscrow();
  const { '*': activePath } = useParams<{
    '*': EscrowRoutesEnum;
  }>();
  const { data: changesRequestedData } = useClientChangesRequestedQuery();

  let menuItems: IEscrowMenuItem[] = cloneDeep(escrowMenuItems);

  if (isSuggestChangesMode)
    menuItems = menuItems.map((item) => ({
      ...item,
      isRequired: false,
      isDisabled: item.path !== activePath,
    }));

  if (
    !isSuggestChangesMode &&
    [EscrowStatusEnum.Draft, EscrowStatusEnum.AdminReview, EscrowStatusEnum.Onboarding].includes(escrow.status)
  )
    menuItems = updateMenuItemsForDraftOrAdminReview({
      agreementSettingsConfigured,
      depositorConfigured,
      beneficiaryConfigured,
    });

  if (!isSuggestChangesMode && escrow.status === EscrowStatusEnum.Onboarding)
    menuItems = menuItems.map((item, index) => {
      if (index < 7) {
        return {
          ...item,
          isDisabled: false,
        };
      }

      return item;
    });

  if (
    !isSuggestChangesMode &&
    [
      EscrowStatusEnum.Active,
      EscrowStatusEnum.Signed,
      EscrowStatusEnum.PendingTermination,
      EscrowStatusEnum.Terminated,
    ].includes(escrow.status)
  ) {
    menuItems = menuItems.map((item, index) => {
      if (index < 8) {
        return {
          ...item,
          isDisabled: false,
          isRequired:
            item.path === EscrowRoutesEnum.Deposits && escrow.depositStatus === 'no_configuration' ? true : false,
        };
      } else return item;
    });
  }

  // render escrow Certificate/Reports menu item only if escrow is active
  if (!isSuggestChangesMode && EscrowStatusEnum.Active === escrow.status) {
    menuItems[8] = { ...menuItems[8], isDisabled: false };
    menuItems[9] = { ...menuItems[9], isDisabled: false };
  }

  if (changesRequestedData?.clientChangesRequested.isRequested && !escrow.escrowTermination)
    menuItems = applySuggestedChangesToMenuItems(menuItems, changesRequestedData);

  if (
    escrow.currentCompanyRole === EscrowPartyTypeEnum.Beneficiary &&
    (!escrow.reportsEnabled || !escrow.showDepositInformation)
  )
    menuItems = menuItems.filter((item) => item.path !== EscrowRoutesEnum.Reports);

  if (isEditAccessMode)
    menuItems = menuItems.map((item) => ({
      ...item,
      isDisabled: item.path !== EscrowRoutesEnum.Members,
    }));

  return <EscrowMenu basePath="escrows" items={menuItems} escrowId={escrow.id} />;
};

export default withSuggestedChangesApi(EscrowMenuContainer);
