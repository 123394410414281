import { Button } from 'antd';
import { useNavigate } from 'react-router';

import { JourneyLayout } from 'src/components/Journey';
import { useSaasEscrow } from 'src/context/saas';
import { SaasEnvironmentStatusEnum } from 'src/graphql/schema';

import ActivateSaasEnvironmentContainer from './Actions/ActivateSaasEnvironmentContainer';
import TerminateSaasEnvironmentContainer from './Actions/TerminateSaasEnvironmentContainer';

const SaasFooter = JourneyLayout.Footer;

const actionItemsKeys = ['activate-saas', 'close', 'request-termination'] as const;

const SaasFooterContainer = () => {
  const navigate = useNavigate();
  const { environment } = useSaasEscrow();

  let actionItems: { key: (typeof actionItemsKeys)[number]; component: React.ReactNode }[] = [
    {
      key: 'close',
      component: (
        <Button
          type="default"
          onClick={() => {
            navigate('/saas-escrows');
          }}
        >
          Close
        </Button>
      ),
    },
  ];

  if (environment.status === SaasEnvironmentStatusEnum.Draft) {
    actionItems = [
      ...actionItems.filter(({ key }) => key !== 'close'),
      {
        key: 'activate-saas',
        component: <ActivateSaasEnvironmentContainer />,
      },
    ];
  }

  if (environment.policy.requestTermination) {
    actionItems = [
      {
        key: 'request-termination',
        component: <TerminateSaasEnvironmentContainer id={environment.id} />,
      },
      ...actionItems,
    ];
  }

  return (
    <SaasFooter justify={environment.policy.requestTermination ? 'space-between' : 'end'}>
      {actionItems.map(({ key, component }) => (
        <SaasFooter.Item key={key}>{component}</SaasFooter.Item>
      ))}
    </SaasFooter>
  );
};

export default SaasFooterContainer;
