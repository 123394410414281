import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router';
import { message, Button } from 'antd';

import { EscrowRoleEdit } from 'src/components/Escrow/Role';
import { useCreateEscrowMutation, EscrowsDocument, EscrowPartyTypeEnum, AgreementFormEnum } from 'src/graphql/schema';
import { JourneyModalLayout } from 'src/components/Journey/ModalLayout';
import { EscrowRoutesEnum } from 'src/pages/Escrow';
import { getApiError } from 'src/utils/errors';

const EscrowModalFooter = JourneyModalLayout.Footer;
const EscrowModalMain = JourneyModalLayout.Main;

const EscrowRoleEditContainer = () => {
  const [role, setRole] = useState<EscrowPartyTypeEnum | ''>('');
  const [agreement, setAgreement] = useState<AgreementFormEnum | ''>();

  const navigate = useNavigate();
  const [createEscrow] = useCreateEscrowMutation({
    refetchQueries: [EscrowsDocument],
  });

  const onSave = async () => {
    try {
      const { data } = await createEscrow({
        variables: {
          escrowRole: role as EscrowPartyTypeEnum,
          agreementForm: agreement as AgreementFormEnum,
        },
      });

      if (data?.createEscrow?.success) {
        navigate(`/escrows/${data?.createEscrow?.escrow?.id}/${EscrowRoutesEnum.Members}`);
      }
    } catch (e) {
      getApiError(e, message.error);
    }
  };

  const onRoleChange = (value: string) => {
    if (value === role) return setRole('');

    if (value === EscrowPartyTypeEnum.Beneficiary) {
      setAgreement('');
    }

    setRole(value as EscrowPartyTypeEnum);
  };

  const onAgreementChange = (value: string) => {
    if (value === agreement) return setAgreement('');

    setAgreement(value as AgreementFormEnum);
  };

  return (
    <Fragment>
      <EscrowModalMain>
        <EscrowRoleEdit
          role={{
            activeValue: role,
            onChange: onRoleChange,
          }}
          agreement={{
            activeValue: agreement,
            onChange: onAgreementChange,
          }}
        />
      </EscrowModalMain>

      <EscrowModalFooter justify="end">
        <EscrowModalFooter.Item>
          <Button type="primary" disabled={!role || !agreement} onClick={onSave}>
            Save & Create
          </Button>
        </EscrowModalFooter.Item>
      </EscrowModalFooter>
    </Fragment>
  );
};

export default EscrowRoleEditContainer;
