import { Outlet } from 'react-router';

import { AuthLayout } from 'src/components/Layout';
import { Logo } from 'src/components/Svg/Images';

export default function InviteLayout() {
  return (
    <AuthLayout>
      <Logo className="invite-screen__logo" />
      <Outlet />
    </AuthLayout>
  );
}
