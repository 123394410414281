import { useState } from 'react';
import { useParams } from 'react-router';
import { ApolloError } from '@apollo/client/errors';
import { useSuspenseQuery } from '@apollo/client';

import { EscrowDocument } from 'src/graphql/schema';
import { useEscrowMembersSlice } from 'src/slices';

import { createRequiredContext } from '../createRequiredContext';

import type { IEscrowQuery, IEscrow } from 'src/graphql/schema';

type IUiState = {
  isSuggestChangesMode: boolean;
  isSendModalOpen: boolean;
  isConfirmModalOpen: boolean;
  isSendForReviewBtnDisabled: boolean;
  members: ReturnType<typeof useEscrowMembersSlice>;
};

const [useEscrow, EscrowProvider] = createRequiredContext<{
  escrow: IEscrow;
  error?: ApolloError;
  ui: IUiState;
  setSuggestChangesMode: (isSuggestChangesMode: boolean) => void;
  setEnableSendForReviewBtn: (isSendForReviewBtnDisabled: boolean) => void;
  setSendModalOpen: (isSendModalOpen: boolean) => void;
  setConfirmModalOpen: (isConfirmModalOpen: boolean) => void;
  setDefaultUi: () => void;
  setCustomUi: (customUi: Partial<IUiState>) => void;
  refetch: () => void;
}>();

const EscrowContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [ui, setUi] = useState({
    isSuggestChangesMode: false,
    isSendModalOpen: false,
    isConfirmModalOpen: false,
    isSendForReviewBtnDisabled: true,
  });
  const { escrowId } = useParams();
  const { data, error, refetch } = useSuspenseQuery<IEscrowQuery>(EscrowDocument, {
    variables: { escrowId },
    skip: !escrowId,
  });
  const uiMembers = useEscrowMembersSlice();

  const setDefaultUi = () =>
    setUi({
      isSuggestChangesMode: false,
      isSendModalOpen: false,
      isConfirmModalOpen: false,
      isSendForReviewBtnDisabled: true,
    });

  const setCustomUi = (customUi: Partial<IUiState>) => setUi((ui) => ({ ...ui, ...customUi }));

  const setSuggestChangesMode = (isSuggestChangesMode: boolean) => setUi((ui) => ({ ...ui, isSuggestChangesMode }));

  const setEnableSendForReviewBtn = (isSendForReviewBtnDisabled: boolean) =>
    setUi((ui) => ({ ...ui, isSendForReviewBtnDisabled: !isSendForReviewBtnDisabled }));

  const setSendModalOpen = (isSendModalOpen: boolean) => setUi((ui) => ({ ...ui, isSendModalOpen }));

  const setConfirmModalOpen = (isConfirmModalOpen: boolean) => setUi((ui) => ({ ...ui, isConfirmModalOpen }));

  const providerValue = {
    escrow: data?.escrow as IEscrow,
    error,
    ui: {
      ...ui,
      members: uiMembers,
    },
    setSuggestChangesMode,
    setEnableSendForReviewBtn,
    setSendModalOpen,
    setConfirmModalOpen,
    setDefaultUi,
    setCustomUi,
    refetch,
  };

  return <EscrowProvider value={providerValue}>{children}</EscrowProvider>;
};

export { useEscrow, EscrowContextProvider };
