import { redirect } from 'react-router';

import { EscrowNewModalLayoutContainer, EscrowRoleEditContainer } from 'src/containers/Escrow';
import { escrowRouteElements, EscrowRoutesEnum } from 'src/pages/Escrow';
import { JourneyModal } from 'src/components/Journey/ModalLayout';
import { EscrowSlugLayout } from 'src/pages/Escrow/ui';

import Escrows from '../pages/Escrows/Escrows';

import type { RouteObject } from 'react-router';

export const escrowRoutes: RouteObject = {
  path: 'escrows',
  element: <Escrows />,
  children: [
    {
      element: <JourneyModal />,
      children: [
        {
          element: <EscrowNewModalLayoutContainer />,
          children: [
            {
              path: 'new',
              element: <EscrowRoleEditContainer />,
            },
          ],
        },
      ],
    },
  ],
};

export const escrowSingleRoutes: RouteObject = {
  path: 'escrows',
  element: <EscrowSlugLayout />,
  children: [
    {
      loader: ({ params }) => {
        const { escrowId = null, '*': splat = '' } = params;

        if (escrowId && !splat) return redirect(`/escrows/${escrowId}/${EscrowRoutesEnum.Basics}`);

        const path = splat?.split('/').length > 1 ? splat?.split('/')[0] : splat;

        if (!Object.values(EscrowRoutesEnum).includes(path as EscrowRoutesEnum)) return redirect('/404');

        return null;
      },
      path: ':escrowId/*',
      children: escrowRouteElements,
    },
  ],
};
