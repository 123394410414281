import { Button, Popover, Alert } from 'antd';
import { Fragment, useState } from 'react';
import { Link } from 'react-router';

import {
  useStartBackupMutation,
  useStopBackupMutation,
  BackupStatusEnum,
  BackupAssetStatusEnum,
} from 'src/graphql/schema';
import { useBackup } from 'src/context/backup';
import styles from 'src/components/Backup/Summary/Card/BackupSummaryCard.module.scss';
import { getApiError, ClientError } from 'src/utils/errors';
import { message } from 'src/components/Misc';

const BackupSummaryActionsContainer = () => {
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const { backup } = useBackup();
  const [stopBackup, { loading: stopLoading }] = useStopBackupMutation();
  const [startBackup, { loading: resumeLoading }] = useStartBackupMutation();

  const onStop = async () => {
    try {
      const { data } = await stopBackup({
        variables: { id: backup?.id || '' },
      });

      if (data?.stopBackup?.success) {
        message.success('Backup stopped successfully');
      } else {
        throw new ClientError(data?.stopBackup?.errors?.[0]);
      }
    } catch (error) {
      getApiError(error, message.error);
      throw error;
    }
  };

  const onResume = async () => {
    try {
      const { data } = await startBackup({
        variables: { id: backup?.id || '' },
      });

      if (data?.startBackup?.success) {
        message.success('Backup started successfully');
      } else {
        throw new ClientError(data?.startBackup?.errors?.[0]);
      }
    } catch (error) {
      getApiError(error, message.error);
      throw error;
    }
  };

  const handleOpenChange = (open: boolean) => setIsOpenPopover(open);

  if (
    backup.status !== BackupStatusEnum.Active &&
    (!backup.backupAssets.length ||
      backup.backupAssets.every((asset) => asset.status === BackupAssetStatusEnum.Stopped))
  )
    return (
      <Popover
        content={
          <Alert
            message="All assets were disabled"
            description={
              <Fragment>
                Enable at least one asset from <Link to={`/backups/${backup.id}/assets`}>here</Link>
              </Fragment>
            }
            type="warning"
            showIcon
            className={styles.alert}
          />
        }
        trigger="click"
        open={isOpenPopover}
        onOpenChange={handleOpenChange}
      >
        <Button type="primary" className={styles['btn-start']}>
          Resume
        </Button>
      </Popover>
    );

  if (backup.status === BackupStatusEnum.Stopped)
    return (
      <Button type="primary" className={styles['btn-start']} onClick={onResume} loading={resumeLoading}>
        Resume
      </Button>
    );

  return (
    <Button type="primary" className={styles['btn-disconnect']} onClick={onStop} loading={stopLoading}>
      Stop
    </Button>
  );
};

export default BackupSummaryActionsContainer;
