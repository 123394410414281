import { useNavigate } from 'react-router-dom';
import { useSuspenseQuery } from '@apollo/client';
import { Fragment } from 'react';

import { Button } from '@/components/ui/button';
import { CompanySolutionsDocument, CompanyDocument } from 'src/graphql/schema';
import { withSuspense } from 'src/hoc';

import type { ICompanySolutionsQuery, ICompanyQuery } from 'src/graphql/schema';

import { organizationStatusMap, solutionsMap } from './model';
import { HomeBanner, ActionCard, HomeSkeleton } from './ui';

const prioritySolutionsType: Record<keyof typeof solutionsMap, number> & { empty: number } = {
  empty: 0,
  escrow: 1,
  backup: 2,
  saas: 3,
  custodian: 4,
};

const HomeContainer = () => {
  const { data: solutionsData } = useSuspenseQuery<ICompanySolutionsQuery>(CompanySolutionsDocument, {
    fetchPolicy: 'cache-and-network',
  });
  const { data: companyData } = useSuspenseQuery<ICompanyQuery>(CompanyDocument, {
    fetchPolicy: 'cache-and-network',
  });
  const navigate = useNavigate();

  const solutionsDataMap: Record<keyof typeof solutionsMap, { activeValue: number; totalValue: number } | null> = {
    backup: createSolutionData(solutionsData.activeBackups.nodesCount, solutionsData.totalBackups.nodesCount),
    escrow: createSolutionData(solutionsData.activeEscrows.nodesCount, solutionsData.totalEscrows.nodesCount),
    saas: createSolutionData(
      solutionsData.activeSaasEnvironments.nodesCount,
      solutionsData.totalSaasEnvironments.nodesCount,
    ),
    custodian: createSolutionData(
      solutionsData.activeCustodianProfiles.nodesCount,
      solutionsData.totalCustodianProfiles.nodesCount,
    ),
  };
  const solutionsItems = Object.keys(solutionsMap).map((key) => {
    const data = solutionsDataMap[key as keyof typeof solutionsDataMap];

    return {
      id: key,
      ...solutionsMap[key as keyof typeof solutionsMap],
      ...(data && { data }),
    };
  });
  const currentPrioritySolution = solutionsItems.reduce<keyof typeof prioritySolutionsType>((acc, { id, data }) => {
    if (!acc) return id as keyof typeof solutionsMap;
    return id in prioritySolutionsType &&
      Boolean(data?.activeValue) &&
      prioritySolutionsType[id as keyof typeof solutionsMap] > prioritySolutionsType[acc]
      ? (id as keyof typeof solutionsMap)
      : acc;
  }, 'empty');
  const currentOrgStatus = organizationStatusMap[currentPrioritySolution];

  const onGetCertificate = () => navigate(currentOrgStatus?.certificateUrl ?? '');

  return (
    <Fragment>
      <div className="flex w-full gap-4 mb-6">
        <HomeBanner {...currentOrgStatus} subtitle={companyData.company.companyName ?? ''} className="grow" />

        <div className="flex-shrink-0 p-6 border border-border rounded-lg w-[232px] shadow-sm">
          <div className="text-base font-bold text-card-foreground mb-1">Assure your customers</div>
          <p className="text-sm text-muted-foreground mb-4">Show your customers that their data is secured.</p>
          <Button variant="outline" disabled={currentPrioritySolution === 'empty'} onClick={onGetCertificate}>
            Get Certificate
          </Button>
        </div>
      </div>

      <div className="h-[1px] w-full bg-border mb-6" />

      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mb-5">
        {solutionsItems.map(({ id, ...solution }) => (
          <ActionCard key={id} {...solution} />
        ))}
      </div>
    </Fragment>
  );
};

function createSolutionData(activeValue: number, totalValue: number) {
  return totalValue ? { activeValue, totalValue } : null;
}

export default withSuspense(HomeContainer, HomeSkeleton);
