import { Outlet } from 'react-router';
import { Fragment } from 'react';

import { cookieStore } from 'src/utils/cookieStore';
import { SidebarProvider } from '@/components/ui/sidebar';
import { Toaster } from '@/components/ui/toaster';

type Props = {
  sidebar: React.ElementType;
};

const Layout = ({ sidebar: Sidebar }: Props) => {
  const defaultOpen = cookieStore.get('sidebar:state')?.value === 'true';

  return (
    <Fragment>
      <div className="d-flex h-100vh">
        <SidebarProvider
          style={
            {
              '--sidebar-width': '256px',
              '--sidebar-width-mobile': '48px',
            } as React.CSSProperties
          }
          defaultOpen={defaultOpen}
        >
          <Sidebar />

          <Outlet />
        </SidebarProvider>
      </div>

      <Toaster />
    </Fragment>
  );
};
export default Layout;
