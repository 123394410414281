/* eslint-disable react/display-name */
import classNames from 'classnames';
import { Skeleton } from 'antd';

import styles from './BackupResult.module.scss';

export type IBackupResultItemData = {
  id: string;
  name?: string;
  version: string;
  date?: string;
  text?: string;
  type: 'scheduled' | 'latest' | 'skipped' | 'stopped' | 'failed';
  lastBackup?: string | null;
};

type Props = {
  data: IBackupResultItemData;
  actions?: React.ReactNode;
  className?: string;
};

const BackupResultItemContent = ({ data, actions, className }: Props) => {
  const itemWrapperClasses = classNames(styles['item-wrapper'], className);
  const typeClasses = classNames(styles['item-type'], styles[data.type]);

  return (
    <div className={itemWrapperClasses}>
      <div className={styles['item-main__col']}>
        {data.name && <h6 className={`${styles['item-name']} font-bold`}>{data.name}</h6>}

        <div className={typeClasses}>{data.version}</div>
        {data.date && <div className={styles['item-date']}>{data.date}</div>}
        {data.text && <p className={styles['item-text']}>{data.text}</p>}
      </div>

      {actions && <div className={styles['item-actions__col']}>{actions}</div>}
    </div>
  );
};

BackupResultItemContent.Skeleton = function () {
  return (
    <Skeleton
      active
      title={{
        width: 300,
      }}
      paragraph={{
        rows: 2,
      }}
    />
  );
};

export default BackupResultItemContent;
