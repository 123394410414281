import { Button, Modal } from 'antd';
import { Link, useNavigate } from 'react-router';

import { useMigrations } from 'src/context/migrations';

export default function InfoModal() {
  const navigate = useNavigate();
  const {
    ui: { isInfoModalVisible },
    setIsInfoModalVisible,
  } = useMigrations();

  const onClose = () => setIsInfoModalVisible(false);
  const onAddIntegration = () => {
    setIsInfoModalVisible(false);
    navigate('/integrations');
  };

  return (
    <Modal
      className="info-migrations-modal"
      open={isInfoModalVisible}
      onCancel={onClose}
      footer={null}
      title="Migrate your Assets"
    >
      <p className="info-migrations-modal__subtitle">
        You&apos;re currently using legacy connection methods to the assets listed on this page.
      </p>
      <div className="info-migrations-modal__steps">
        <p className="info-migrations-modal__steps-title">
          In order to migrate to our new OAuth powered integration method, please follow these steps:
        </p>

        <div className="info-migrations-modal__step">
          <div className="d-flex align-items-center">
            <span className="info-migrations-modal__step-count">1</span>
            <p className="info-migrations-modal__step-text">
              Add an <Link to="/integrations">Integration</Link>
            </p>
          </div>
          <p className="info-migrations-modal__step-info">
            Setup the connection between your SCM platform where you keep your assets and the Codekeeper platform.
          </p>
        </div>

        <div className="info-migrations-modal__step">
          <div className="d-flex align-items-center">
            <span className="info-migrations-modal__step-count">2</span>
            <p className="info-migrations-modal__step-text">Migrate your Assets</p>
          </div>
          <p className="info-migrations-modal__step-info">
            Use this Migrations page to migrate all assets on the list. Multi-select is possible for assets from the
            same SCM platform.
          </p>
        </div>
        <div className="info-migrations-modal__step">
          <div className="d-flex align-items-center">
            <span className="info-migrations-modal__step-count">3</span>
            <p className="info-migrations-modal__step-text">After Migration</p>
          </div>
          <p className="info-migrations-modal__step-info">
            You will now be able to see all assets in the Deposits tab. It may take 24 hours for the first deposit using
            this new method to show.
          </p>
        </div>
      </div>

      <p className="info-migrations-modal__note">
        <strong>Note: </strong> Your current connection method and deposited assets will remain active until you
        completed the migration. All your escrows are still active and operational while the migration is pending.
        Please <Link to="/support">contact support</Link> if you have any questions.
      </p>

      <div className="info-migrations-modal__btns">
        <Button type="default" className="ant-btn-default--primary mr-3" onClick={onClose}>
          I understand
        </Button>
        <Button type="primary" onClick={onAddIntegration}>
          Add integration
        </Button>
      </div>
    </Modal>
  );
}
