import { Fragment, useState, useEffect } from 'react';
import { capitalize, debounce } from 'lodash';
import { useSuspenseQuery } from '@apollo/client';

import { message } from 'src/components/Misc';
import {
  useAssetsLazyQuery,
  useDestroyAssetMutation,
  IAssetsQuery,
  AssetsDocument,
  AssetsOrderByEnum,
  IAssetsQueryVariables,
  EscrowStatusEnum,
  EscrowPartyTypeEnum,
  QueryOrderEnum,
} from 'src/graphql/schema';
import useSorting from 'src/hooks/use-sorting';
import {
  DepositAssetsTable,
  ITableRow,
  EmptyDepositAssets,
  DepositAssetsActions,
  DepositAssetsHeader,
} from 'src/components/Deposit';
import { SuggestChangesSingle } from 'src/components/SuggestChanges';
import { useEscrow } from 'src/context/escrow';
import { useDepositsSuggestChanges } from 'src/context/suggest-changes';
import styles from 'src/components/Deposit/Deposit.module.scss';
import { DEPOSITS_PER_PAGE, ASSETS_LIMIT } from 'src/shared/constants';
import { withSuspense } from 'src/hoc';
import { AddDepositContextProvider, useAddDeposit } from 'src/context/deposit';
import { AddDepositContainer } from 'src/containers/Deposits';

import { getDepositsAssets } from './lib';

export type IAssetNodes = NonNullable<IAssetsQuery['assets']>['nodes'];

const Header = ({
  hideAddBtn,
  isEmpty,
  showRequestChangeHelper,
  isRequired,
}: {
  hideAddBtn: boolean;
  isEmpty: boolean;
  showRequestChangeHelper: boolean;
  isRequired: boolean;
}) => {
  const { escrow } = useEscrow();
  const {
    ui: { selectEscrow },
  } = useAddDeposit();

  const toggleModal = () => selectEscrow(escrow.id);

  return (
    <DepositAssetsHeader
      hideAddBtn={hideAddBtn}
      toggleModal={toggleModal}
      showRequestChangeHelper={showRequestChangeHelper}
      isEmpty={isEmpty}
      isRequired={isRequired}
    />
  );
};

const EmptyContent = ({ totalNodes = 0 }) => {
  const { escrow } = useEscrow();
  const {
    ui: { selectEscrow },
  } = useAddDeposit();

  const toggleModal = () => selectEscrow(escrow.id);

  return (
    <EmptyDepositAssets
      isEmpty={!totalNodes}
      isTerminated={escrow.status === EscrowStatusEnum.Terminated}
      isAssetDisplayDisabled={!escrow.showDepositInformation}
      currentCompanyRole={escrow?.currentCompanyRole as 'depositor' | 'beneficiary'}
      onMakeDeposit={toggleModal}
      className={styles['empty-deposits']}
    />
  );
};

const DepositsAssetsContainer = () => {
  const [totalNodes, setTotalNodes] = useState(0);
  const [currentAssets, setCurrentAssets] = useState<ITableRow[]>([]);
  const [currentPage, setCurrentPage] = useState<number | undefined>(1);
  const [filter, setFilter] = useState<AssetsOrderByEnum | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [order, setOrder] = useSorting();

  const {
    escrow,
    ui: { isSuggestChangesMode },
  } = useEscrow();
  const { suggestedChanges, deletedIdAssets, onRemoveAsset, onUndoRemovedAsset } = useDepositsSuggestChanges();
  const { data } = useSuspenseQuery<IAssetsQuery>(AssetsDocument, {
    variables: { escrowId: escrow.id, perPage: ASSETS_LIMIT, page: 1 },
    skip: !escrow.id,
  });
  const [getAssets, { loading: lazyLoading, refetch }] = useAssetsLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      escrowId: escrow.id,
      perPage: ASSETS_LIMIT,
      page: currentPage,
    },
    onCompleted: ({ assets }) => {
      const assetsNodes = assets?.nodes;

      if (!assetsNodes.length) return;

      setCurrentAssets(getDepositsAssets(assetsNodes));
    },
  });

  useEffect(() => {
    getAssets();
    setTotalNodes(data?.assets?.nodesCount || 0);
  }, [suggestedChanges, data]);

  const [destroyAsset] = useDestroyAssetMutation();

  const onDestroyAsset = async (assetId: string) => {
    try {
      const { data } = await destroyAsset({
        variables: {
          assetId,
        },
      });

      if (data?.destroyAsset?.success) {
        setCurrentAssets((prevAssets) => prevAssets.filter((asset) => asset.action.id !== assetId));
      } else {
        throw new Error(data?.destroyAsset?.errors?.[0]);
      }
    } catch (error: unknown) {
      if (error instanceof Error) message.error(error.message || 'Something went wrong!');
    }
  };

  const getCurrentPage = async (value?: number) => {
    setCurrentPage(value);

    await getAssets({
      variables: {
        page: value,
        perPage: ASSETS_LIMIT,
        ...(filter && { orderBy: filter }),
      },
    });
  };

  const onOrderChange = async () => {
    let currentOrder = order;

    if (currentOrder === 'asc') {
      currentOrder = QueryOrderEnum.Desc;
    } else {
      currentOrder = QueryOrderEnum.Asc;
    }

    setOrder();

    await getAssets({
      variables: {
        page: currentPage,
        perPage: DEPOSITS_PER_PAGE,
        queryFilter: searchQuery,
        order: currentOrder,
      },
    });
  };

  const onFilterChange = async (value: AssetsOrderByEnum | null) => {
    setFilter(value);

    const variables: IAssetsQueryVariables = {
      page: currentPage,
      perPage: DEPOSITS_PER_PAGE,
      queryFilter: searchQuery,
      order,
      orderBy: value,
    };

    if (!value) delete variables.orderBy;

    await getAssets({
      variables,
    });
  };

  const onSearchChange = debounce(async (value: string) => {
    try {
      setSearchQuery(value);

      const { data } = await getAssets({
        variables: {
          page: 1,
          perPage: DEPOSITS_PER_PAGE,
          queryFilter: value,
          order,
        },
      });
      setTotalNodes(data?.assets?.nodesCount || 0);
      setCurrentAssets(getDepositsAssets(data?.assets?.nodes as IAssetNodes));
    } catch (error) {
      message.error('Something went wrong.');
    }
  }, 500);

  const deletedAssetsData = suggestedChanges?.payload?.['deleted_assets'] || [];
  const escrowTerminated = escrow.status === EscrowStatusEnum.Terminated;
  const hideAddBtn = escrowTerminated || escrow.currentCompanyRole === EscrowPartyTypeEnum.Beneficiary;
  const suggestedPartyLabel =
    suggestedChanges?.creator === escrow.currentCompanyRole ? 'You' : capitalize(suggestedChanges?.creator);
  const acceptedPartyLabel =
    suggestedChanges?.receiver === escrow.currentCompanyRole ? 'You' : capitalize(suggestedChanges?.receiver);
  const isEscrowAbleDestroyAssets =
    escrow.status === EscrowStatusEnum.Signed && escrow.currentCompanyRole === EscrowPartyTypeEnum.Depositor;

  const showEmptyState =
    (!totalNodes && !searchQuery) ||
    escrowTerminated ||
    (!escrow.showDepositInformation && escrow?.currentCompanyRole === EscrowPartyTypeEnum.Beneficiary);

  return (
    <Fragment>
      <AddDepositContextProvider refetch={refetch} softwareType="escrow">
        <Header
          hideAddBtn={!hideAddBtn && !isSuggestChangesMode}
          showRequestChangeHelper={
            escrow.status === EscrowStatusEnum.Active &&
            !deletedAssetsData.length &&
            escrow.currentCompanyRole === EscrowPartyTypeEnum.Depositor
          }
          isEmpty={!totalNodes && !searchQuery}
          isRequired={escrow.currentCompanyRole === EscrowPartyTypeEnum.Depositor && !totalNodes && !searchQuery}
        />

        {showEmptyState && <EmptyContent totalNodes={totalNodes} />}

        <AddDepositContainer />
      </AddDepositContextProvider>

      {!showEmptyState && (
        <Fragment>
          {Boolean(deletedAssetsData.length) && (
            <SuggestChangesSingle className={styles['container--max-content']} status={suggestedChanges?.status || ''}>
              {suggestedChanges?.status === 'accepted' &&
                `${acceptedPartyLabel} accepted the removal of ${deletedAssetsData.length} assets from this escrow.`}
              {suggestedChanges?.status === 'pending' && (
                <Fragment>
                  {suggestedPartyLabel} suggested to remove
                  <strong> {deletedAssetsData.length} assets</strong> for this escrow. Please check the asset table
                  below for assets with the status <strong>&quot;Removal requested&quot;</strong>.
                </Fragment>
              )}
            </SuggestChangesSingle>
          )}

          <DepositAssetsActions
            onFilterChange={onFilterChange}
            onSearchChange={onSearchChange}
            onOrderChange={onOrderChange}
            filter={filter}
            order={order}
          />

          <DepositAssetsTable
            assetsData={currentAssets}
            pagination={{
              current: currentPage,
              total: totalNodes,
              onChange: getCurrentPage,
            }}
            isLoading={lazyLoading}
            {...(isSuggestChangesMode && deletedIdAssets
              ? {
                  isSuggestedChanges: true,
                  onRemoveAsset,
                  onUndoRemovedAsset,
                  deletedIdAssets,
                }
              : {
                  isSuggestedChanges: false,
                  onDestroyAsset: isEscrowAbleDestroyAssets ? onDestroyAsset : undefined,
                })}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default withSuspense(DepositsAssetsContainer);
