import { CreateNewEnvironmentContainer } from 'src/containers/Saas/Environments';
import { SaasEscrowsContainer } from 'src/containers/Saas/Escrows';
import { Main } from 'src/components/Layout';
import { usePageMeta } from 'src/hooks';

const SaasEscrows = () => {
  usePageMeta('SaaS Escrow');

  return (
    <Main title="SaaS Escrow" headerActions={<CreateNewEnvironmentContainer />}>
      <SaasEscrowsContainer />
    </Main>
  );
};

export default SaasEscrows;
