import { Empty } from 'antd';
import { Link } from 'react-router';
import classNames from 'classnames';

import { ImageEmptyBox } from 'src/components/Svg/Images';

export default function EmptyEscrows({ status }: { status: string }) {
  return (
    <Empty
      image={<ImageEmptyBox aria-label="empty" />}
      description={
        <span className="d-flex flex-column">
          <span
            className={classNames('deposit__empty-title d-block', {
              '-no-escrows': status,
            })}
          >
            {status ? `No Escrows with ${status} Status` : 'Set Up Your First Escrow'}
          </span>
          {!status && (
            <span className="deposit__empty-text d-block">
              Set up your first escrow to protect your code and data.
              <br />
              Customize it to fit your situation.
            </span>
          )}

          {!status && (
            <Link to={`/escrows/new`} className="deposit__empty-new-btn">
              Create new escrow
            </Link>
          )}
        </span>
      }
    />
  );
}
