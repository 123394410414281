import { useParams, useNavigate } from 'react-router';
import { Button } from 'antd';

import { JourneyLayout } from 'src/components/Journey';
import { CustodianRoutesEnum } from 'src/pages/Custodian';
import { useCustodianProfile } from 'src/context/custodian';
import { CustodianProfileStatusEnum } from 'src/graphql/schema';

import UpdateCustodianAssetsContainer from '../Assets/UpdateCustodianAssetsContainer';

import type { IJourneyFooter } from 'src/components/Journey';

const Footer = JourneyLayout.Footer;

const SubmitProfile = () => {
  const {
    custodianProfile: { policy },
    ui: { showSubmitModal },
  } = useCustodianProfile();

  return (
    <Button type="primary" disabled={!policy.submitForReview} onClick={showSubmitModal}>
      Save & Submit
    </Button>
  );
};

const Back = () => {
  const navigate = useNavigate();

  const onBack = () => navigate('/custodian-profiles');

  return <Button onClick={onBack}>Back to Dashboard</Button>;
};

const RequestTermination = () => {
  const {
    ui: { showTerminateModal },
  } = useCustodianProfile();

  return (
    <Button danger ghost onClick={showTerminateModal}>
      Request Termination
    </Button>
  );
};

const UpdateCustodianDraftAssets = () => {
  const {
    custodianProfile: { id },
    ui: {
      assets: { setInitialData },
    },
  } = useCustodianProfile();
  const navigate = useNavigate();

  const onSuccess = () => {
    navigate(`/custodian-profiles/${id}/${CustodianRoutesEnum.Operations}`);
    setInitialData();
  };

  return <UpdateCustodianAssetsContainer successCallback={onSuccess} />;
};

const draftActionsMap = {
  [CustodianRoutesEnum.Assets]: UpdateCustodianDraftAssets,
  [CustodianRoutesEnum.Operations]: SubmitProfile,
};

const CustodianModalFooterContainer = () => {
  const { '*': activePath } = useParams<{
    '*': CustodianRoutesEnum;
  }>();
  const {
    custodianProfile: { status, policy },
    isTerminationRequested,
    ui: { assets },
  } = useCustodianProfile();
  let actionItems: {
    key: string;
    component: React.ReactNode;
  }[] = [];
  let justifyContent: IJourneyFooter['justify'] = 'space-between';

  if (status === CustodianProfileStatusEnum.Draft) {
    justifyContent = 'end';
    const ActionComponent = draftActionsMap[activePath as keyof typeof draftActionsMap] ?? null;
    ActionComponent ? actionItems.push({ key: activePath ?? '', component: <ActionComponent /> }) : null;
  }

  if (policy.requestTermination) {
    actionItems.push(
      { key: 'request-termination', component: <RequestTermination /> },
      { key: 'back', component: <Back /> },
    );
  }

  if (policy.update && assets.isEditMode) {
    justifyContent = 'end';
    actionItems = [
      {
        key: 'hide-manage-assets',
        component: <Button onClick={() => assets.setEditMode(false)}>Discard</Button>,
      },
      {
        key: 'submit',
        component: <UpdateCustodianAssetsContainer submitText="Save Changes" successCallback={assets.setInitialData} />,
      },
    ];
  }

  if (status === CustodianProfileStatusEnum.Terminated || isTerminationRequested) {
    justifyContent = 'end';
    actionItems = [{ key: 'back', component: <Back /> }];
  }

  return (
    <Footer justify={justifyContent}>
      {actionItems.map(({ key, component }) => (
        <Footer.Item key={key}>{component}</Footer.Item>
      ))}
    </Footer>
  );
};

export default CustodianModalFooterContainer;
