import { Fragment } from 'react';
import { Button, Alert } from 'antd';
import { useNavigate } from 'react-router';

import { useSaasEscrow } from 'src/context/saas';
import { ProviderCardList } from 'src/components/Saas/Provider';
import { EmptySaas } from 'src/components/Saas/Empty';

import { getProvider } from '../lib';
import { ActionProviderContainer } from '../Providers';

const SaasEnvironmentsListContainer = () => {
  const { environment, error, hostingProviders, thirdPartyProviders } = useSaasEscrow();
  const navigate = useNavigate();

  if (error) return <Alert message={error.message} type="error" />;

  if (!environment.saasProviders.length)
    return (
      <EmptySaas
        type="provider"
        action={
          <Button type="primary" size="large" onClick={() => navigate(`/saas-escrows/${environment.id}/providers`)}>
            Add new provider
          </Button>
        }
      />
    );

  return (
    <ActionProviderContainer>
      {({ dropdownItemClick }) => (
        <Fragment>
          <ProviderCardList
            canUpdate={environment.policy.update}
            items={hostingProviders.map(getProvider)}
            dropdownItemClick={dropdownItemClick}
            heading={{
              size: 'medium',
              text: 'Hosting Providers',
            }}
            col={4}
          />

          <ProviderCardList
            canUpdate={environment.policy.update}
            items={thirdPartyProviders.map(getProvider)}
            dropdownItemClick={dropdownItemClick}
            heading={{
              size: 'medium',
              text: '3rd party services',
            }}
            col={4}
          />
        </Fragment>
      )}
    </ActionProviderContainer>
  );
};

export default SaasEnvironmentsListContainer;
