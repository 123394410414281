export const eventTypes = [
  'escrow_invitation_sent',
  'agreement_sent_for_review_beneficiary',
  'agreement_sent_for_review_depositor',
  'signatures_requested',
  'escrow_signed',
  'suggested_change_created',
  'escrow_termination_requested',
  'saas_environment_activation_requested',
  'saas_environment_termination_requested',
  'custodian_profile_sent_for_review',
  'custodian_termination_requested',
] as const;

type IInvitationSentPayload = {
  inviting_company_name: string;
  party_type: string;
};

type ISuggestedChangeCreatedPayload = {
  requester_party: string;
};

type IEscrowTerminationRequestedPayload = {
  requester_party: string;
};

type IPayload = IInvitationSentPayload | ISuggestedChangeCreatedPayload | IEscrowTerminationRequestedPayload;

export const getNotificationContent = (
  eventType: (typeof eventTypes)[number],
  payload?: IPayload,
): {
  title: string;
  description: string;
} | null => {
  switch (eventType) {
    case 'escrow_invitation_sent': {
      const { inviting_company_name, party_type } = payload as IInvitationSentPayload;

      return {
        title: 'Escrow Invitation: Accept or Reject',
        description: `${inviting_company_name} has invited you to collaborate on an escrow agreement as their ${party_type}.`,
      };
    }
    case 'agreement_sent_for_review_beneficiary':
    case 'agreement_sent_for_review_depositor':
      return {
        title: 'Escrow Agreement Ready for Review',
        description:
          'Your document is ready for review. Please review the agreement carefully before proceeding to sign.',
      };
    case 'signatures_requested':
      return {
        title: 'Escrow Agreement Sent for Signature',
        description:
          'An e-signature request has been sent to the signatories listed in the escrow. Please check the spam folder if nothing has arrived.  ',
      };
    case 'escrow_signed':
      return {
        title: 'Deposit Assets to Activate Escrow',
        description:
          'The escrow agreement has been successfully executed. To activate the escrow, please complete the final step by depositing the assets.',
      };
    case 'suggested_change_created': {
      const { requester_party } = payload as ISuggestedChangeCreatedPayload;

      return {
        title: 'Review Suggested Changes',
        description: `The ${requester_party} has suggested changes to the escrow. Please review their suggestions carefully before deciding to accept or reject them.`,
      };
    }
    case 'escrow_termination_requested': {
      const { requester_party } = payload as IEscrowTerminationRequestedPayload;

      return {
        title: 'Escrow Termination Requested',
        description: `The ${requester_party} has requested to terminate the escrow. Please review the escrow, and decide whether to accept or reject the request.`,
      };
    }
    case 'saas_environment_activation_requested':
      return {
        title: 'Complete Provider Account Access Setup',
        description:
          'Codekeeper will send you instructions to set up access to your provider accounts. Please follow the steps promptly to continue.',
      };
    case 'saas_environment_termination_requested':
      return {
        title: 'Revoke Provider Account Access',
        description:
          "This escrow is pending termination. Please revoke Codekeeper's access to all your provider accounts.",
      };
    case 'custodian_profile_sent_for_review':
      return {
        title: 'Complete Provider Account Access Setup',
        description:
          'Codekeeper will send you instructions to set up access to your provider accounts. Please follow the steps promptly to continue.',
      };
    case 'custodian_termination_requested':
      return {
        title: 'Revoke Provider Account Access',
        description:
          "This Custodian Profile is pending termination. Please revoke Codekeeper's access to all your provider accounts.",
      };
    default:
      return null;
  }
};
