import { Outlet, useParams } from 'react-router-dom';

import { SaasLayoutContainer } from 'src/containers/Saas';
import { JourneyLayoutSkeleton } from 'src/components/Journey';
import { SaasEscrowContextProvider } from 'src/context/saas';
import { withSuspense } from 'src/hoc';

const SaasEnvironmentLayout = () => {
  const { id = '' } = useParams();

  return (
    <div className="d-flex h-100vh flex-column">
      <SaasEscrowContextProvider environmentId={id}>
        <SaasLayoutContainer>
          <Outlet />
        </SaasLayoutContainer>
      </SaasEscrowContextProvider>
    </div>
  );
};

const FallBack = () => <JourneyLayoutSkeleton indicatorItems={3} menuItems={2} />;

export default withSuspense(SaasEnvironmentLayout, FallBack);
