import { Button } from 'antd';
import { Fragment } from 'react';
import { useSuspenseQuery } from '@apollo/client';

import { ExclusiveOfferContent, ExclusiveOfferCard } from 'src/components/Billing/Cancel/Plan';
import { useCancelationPlan } from 'src/context/billing';
import { EXCLUSIVE_OFFER } from 'src/shared/constants';
import { useAcceptExclusiveOfferMutation, ExclusiveOfferPlansDocument } from 'src/graphql/schema';
import { withSuspense } from 'src/hoc/withSuspense';
import { message } from 'src/components/Misc';

import { mapExclusiveOfferPlans } from '../lib/mapExclusiveOfferPlans';

import type { IExclusiveOfferPlansQuery } from 'src/graphql/schema';

const ExclusiveOfferCardContainer = withSuspense(() => {
  const { data } = useSuspenseQuery<IExclusiveOfferPlansQuery>(ExclusiveOfferPlansDocument);
  const plans = (data.exclusiveOfferPlans?.some(({ discounted }) => discounted) && mapExclusiveOfferPlans(data)) || [];

  return <ExclusiveOfferCard plans={plans} />;
}, ExclusiveOfferCard.Skeleton);

const ExclusiveOfferContainer = () => {
  const {
    ui: { setStep, getBack },
  } = useCancelationPlan();

  const [acceptExclusiveOffer, { loading }] = useAcceptExclusiveOfferMutation();

  const onAcceptOffer = async () => {
    try {
      await acceptExclusiveOffer();
      setStep('offer-confirmation');
    } catch {
      message.error('Something went wrong. Please try again later.');
    }
  };
  const onDeclineOffer = () => setStep('pre-cancelation');

  return (
    <Fragment>
      <ExclusiveOfferContent offerCard={<ExclusiveOfferCardContainer />} />

      <div className="d-flex justify-content-between">
        <Button type="text" onClick={getBack}>
          Back
        </Button>
        <div className="d-flex gap-3">
          <Button onClick={onDeclineOffer}>No Thanks</Button>
          <Button type="primary" onClick={onAcceptOffer} loading={loading}>
            Accept {EXCLUSIVE_OFFER}% off
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default ExclusiveOfferContainer;
