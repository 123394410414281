import React from 'react';
import { NavLink } from 'react-router';
import classNames from 'classnames';
import { Skeleton } from 'antd';

import styles from './JourneyMenu.module.scss';

export type IJourneyMenuItem = {
  path: string;
  label: string;
  icon: React.MemoExoticComponent<(props: React.SVGProps<SVGSVGElement>) => React.JSX.Element>;
  isDisabled?: boolean;
  basePath?: string;
  slug?: string;
  isLoading?: boolean;
};

const InnerContent = ({ label, icon: Icon }: Pick<IJourneyMenuItem, 'icon' | 'label'>) => {
  return (
    <div className={styles['item-wrapper']}>
      <Icon className={styles.icon} />
      <span className={styles.label}>{label}</span>
    </div>
  );
};

const JourneyMenuItem = ({
  basePath,
  slug,
  icon,
  path,
  label,
  isDisabled = false,
  isLoading = false,
  children,
}: React.PropsWithChildren<IJourneyMenuItem>) => {
  if (isLoading)
    return (
      <div aria-label="Loading...">
        <Skeleton.Button className={styles['item-skeleton']} active size="large" block />
      </div>
    );

  const menuItemClasses = classNames(styles.item, {
    [styles.disabled]: isDisabled,
  });

  const urlOptions = ['', basePath, slug, ...(path && [path])];

  return (
    <li className={menuItemClasses}>
      {children}
      {isDisabled ? (
        <InnerContent icon={icon} label={label} />
      ) : (
        <NavLink to={urlOptions.join('/')} className={({ isActive }) => (isActive ? styles.active : '')}>
          <InnerContent icon={icon} label={label} />
        </NavLink>
      )}
    </li>
  );
};

export default JourneyMenuItem;
