import { Outlet, useOutletContext } from 'react-router';

import { withSuspense } from 'src/hoc';
import { JourneyLayoutSkeleton } from 'src/components/Journey/Layout';
import { BackupNewModalLayoutContainer } from 'src/containers/Backup';

const BackupNewLayout = () => {
  const outletContext = useOutletContext();

  return (
    <BackupNewModalLayoutContainer>
      <Outlet context={outletContext} />
    </BackupNewModalLayoutContainer>
  );
};

const FallBack = () => <JourneyLayoutSkeleton indicatorItems={3} menuItems={5} />;

export default withSuspense(BackupNewLayout, FallBack);
