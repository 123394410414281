import { Button } from 'antd';
import { useNavigate } from 'react-router';

const ResetPasswordConfirmation = () => {
  const navigate = useNavigate();

  return (
    <div className="success">
      <p className="text-center contents"> Your password has been reset.</p>
      <p>
        <Button type="primary" onClick={() => navigate('/login')}>
          Sign in with new credentials
        </Button>
      </p>
    </div>
  );
};

export default ResetPasswordConfirmation;
