import { useNavigate } from 'react-router';

import { JourneyLayout, JourneyHeader } from 'src/components/Journey';
import { BackupStatusIndicator, BackupStatusIndicatorEnum } from 'src/components/Backup/StatusIndicator';
import { BackupMenu } from 'src/components/Backup/Menu';
import { backupMenuItems } from 'src/components/Backup/Menu/backupMenu.enum';
import { JourneyNavigation } from 'src/components/Journey/Navigation';
import { AlertActionMessage } from 'src/components/Alert';

const BackupNewModalLayoutContainer = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate();

  const menuItems = backupMenuItems.map((item, index) => (index === 0 ? { ...item, path: '' } : item));

  const onBack = () => navigate('/backups');

  return (
    <JourneyLayout
      header={
        <JourneyHeader
          breadcrumbItems={[
            {
              path: 'backups',
              title: 'Backups',
            },
            {
              title: 'New Backup',
            },
          ]}
          onBack={onBack}
        />
      }
      statusIndicator={<BackupStatusIndicator currentStatus={BackupStatusIndicatorEnum.CONNECT} />}
      menu={<BackupMenu basePath="backups" items={menuItems} backupId="new" />}
    >
      <AlertActionMessage iconType="warn" bgColor="yellow" text="Please select your platform." />
      {children}
      <JourneyNavigation isNextDisabled isPrevHidden />
    </JourneyLayout>
  );
};

export default BackupNewModalLayoutContainer;
