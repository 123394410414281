import { Outlet } from 'react-router';

import { CustodianLayoutContainer, CustodianTerminateProfile } from 'src/containers/Custodian';
import { withSuspense } from 'src/hoc';
import { CustodianProfileContextProvider } from 'src/context/custodian';
import { JourneyLayoutSkeleton } from 'src/components/Journey/Layout';

const CustodianSlugLayout = () => {
  return (
    <div className="d-flex h-100vh flex-column">
      <CustodianProfileContextProvider>
        <CustodianLayoutContainer>
          <Outlet />
        </CustodianLayoutContainer>
        <CustodianTerminateProfile />
      </CustodianProfileContextProvider>
    </div>
  );
};

const FallBack = () => <JourneyLayoutSkeleton indicatorItems={3} menuItems={4} />;

export default withSuspense(CustodianSlugLayout, FallBack);
