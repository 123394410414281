import { Fragment } from 'react';
import { Button, Table } from 'antd';

import { TextBlock, message } from 'src/components/Misc';
import { useAddDeposit } from 'src/context/deposit';
import { useCreateOauthAssetsMutation } from 'src/graphql/schema';
import { ClientError, getApiError } from 'src/utils/errors';

const OAuthDepositConfirmContainer = () => {
  const [createOauthAssets, { loading }] = useCreateOauthAssetsMutation();
  const {
    ui: { integration, selectedEscrowId, oauthData: assetsInput, setInitialData, setStep, getBack },
  } = useAddDeposit();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'oauthRepositoryFullName',
    },
  ];

  const onConfirm = async () => {
    try {
      const { data } = await createOauthAssets({
        variables: {
          escrowId: selectedEscrowId,
          integrationId: integration?.id || '',
          assetsInput,
        },
      });

      if (data?.createOauthAssets?.success) {
        setStep('success');
      } else {
        throw new ClientError(data?.createOauthAssets?.errors?.[0] ?? 'An error occurred');
      }
    } catch (e) {
      getApiError(e, message.error);
    }
  };

  return (
    <Fragment>
      <TextBlock
        title={`Are you sure you want to connect these\nassets to your escrow?`}
        text="These assets will be deposited into our secure vaults."
        className="mb-4"
        classes={{
          title: 'text-lg',
        }}
      />

      <Table
        className="ck-deposit__assets-table mb-4"
        rowHoverable={false}
        columns={columns}
        dataSource={assetsInput}
        pagination={{
          defaultPageSize: 9,
          hideOnSinglePage: true,
        }}
        rowKey="oauthRepositoryId"
      />

      <div className="d-flex justify-content-between">
        <Button type="text" onClick={getBack}>
          Back
        </Button>
        <div className="d-flex justify-content-end gap-3 flex-grow-1">
          <Button onClick={setInitialData}>Cancel</Button>
          <Button type="primary" onClick={onConfirm} loading={loading}>
            Confirm
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default OAuthDepositConfirmContainer;
