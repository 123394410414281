import { Fragment, useState } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router';

import { ModalProceed, ModalConfirmation, message } from 'src/components/Misc';
import { useApproveAgreementMutation, AgreementFormEnum } from 'src/graphql/schema';
import { useEscrow } from 'src/context/escrow';

type Props = {
  escrowId: string;
  canApprove: boolean;
};

const ApproveProceedActionContainer = ({ escrowId, canApprove }: Props) => {
  const [ui, setUi] = useState({
    isConfirmModalOpen: false,
    isProceedModalOpen: false,
    isLoading: false,
  });
  const navigate = useNavigate();
  const { escrow, refetch } = useEscrow();
  const [approveAgreement] = useApproveAgreementMutation();

  const onApproveAndProceed = async () => {
    setUi((prevUi) => ({ ...prevUi, isLoading: true }));

    try {
      const { data } = await approveAgreement({
        variables: {
          escrowId,
        },
      });

      if (data?.approveAgreement?.success) {
        setUi((prevUi) => ({ ...prevUi, isProceedModalOpen: false, isConfirmModalOpen: true }));
      } else {
        throw new Error(data?.approveAgreement?.errors?.[0]);
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        message.error(err.message || 'Something went wrong!');
      }
    } finally {
      setUi((prevUi) => ({ ...prevUi, isLoading: false }));
    }
  };

  const onCloseConfirmation = async () => {
    await refetch();
    setUi((prevUi) => ({ ...prevUi, isConfirmModalOpen: false }));
    navigate('/');
  };

  return (
    <Fragment>
      <Button
        type="primary"
        disabled={!canApprove}
        onClick={() => setUi((prevUi) => ({ ...prevUi, isProceedModalOpen: true }))}
      >
        Approve & Proceed to Signing
      </Button>

      <ModalProceed
        title="Are you sure you have reviewed and approved this version of the agreement?"
        text="By approving this version of the agreement, you will trigger the automated signature process. You will no longer be able to edit the configuration of this escrow."
        isOpen={ui.isProceedModalOpen}
        proceedText="Approve & Send"
        onCancel={() => setUi((prevUi) => ({ ...prevUi, isProceedModalOpen: false }))}
        onProceed={onApproveAndProceed}
        isLoading={ui.isLoading}
      />

      <ModalConfirmation
        isOpen={ui.isConfirmModalOpen}
        title="Your agreement approval has been confirmed!"
        text={
          escrow.agreement?.agreementForm === AgreementFormEnum.Bipartite
            ? 'The e-signature process will now be initiated.'
            : 'As soon as the other party completes their review and gives their approval, we will initiate the signature process.'
        }
        onClose={onCloseConfirmation}
        okText="Back to Dashboard"
      />
    </Fragment>
  );
};

export default ApproveProceedActionContainer;
