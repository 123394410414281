import { Fragment, useState } from 'react';
import { Button } from 'antd';
import { useNavigate, useOutletContext } from 'react-router';
import { useSuspenseQuery, useReadQuery } from '@apollo/client';

import { apolloClient, apolloCache } from 'src/graphql';
import { TextBlock, ModalProceed, message } from 'src/components/Misc';
import { JourneyLayout, JourneyFooter } from 'src/components/Journey';
import { BackupEmpty } from 'src/components/Backup/Empty';
import { useCreateBackupMutation, ProfileDocument, BackupReadyIntegrationsDocument } from 'src/graphql/schema';
import { IntegrationRadioGroup as BackupPlatformRadioGroup } from 'src/components/Integration/Radio';
import { ClientError, getApiError } from 'src/utils/errors';
import { writeNewBackupsCache } from 'src/graphql/client/cache/backups';

import type { PartialDeep } from 'type-fest';
import type { IBackupOutletContext } from 'src/pages/Backups';
import type {
  IOauthIntegration,
  IProfileQuery,
  IBackupReadyIntegrationsQuery,
  IBackupsQuery,
  IBackup,
  IIntegrationInterface,
  ICustomIntegration,
} from 'src/graphql/schema';

const clientOwnerResolver = ({ creator }: { creator: { id: string } }) => {
  const dataProfile = apolloCache.readQuery<IProfileQuery>({ query: ProfileDocument });

  if (!dataProfile) return false;

  return creator?.id === dataProfile.profile.id;
};

const resolvers = {
  OauthIntegration: {
    isClientOwner: clientOwnerResolver,
  },
  CustomIntegration: {
    isClientOwner: clientOwnerResolver,
  },
};

const BackupPlatformNewContainer = () => {
  apolloClient.addResolvers(resolvers);
  const [createBackup, { loading }] = useCreateBackupMutation();

  const [selectedIntegrationId, setSelectedIntegrationId] = useState('');
  const [isModalProceedOpen, setIsModalProceedOpen] = useState(false);
  const navigate = useNavigate();
  const { queryRef } = useOutletContext<IBackupOutletContext>();

  useReadQuery<IBackupsQuery>(queryRef);
  useSuspenseQuery(ProfileDocument);
  const { data } = useSuspenseQuery<IBackupReadyIntegrationsQuery>(BackupReadyIntegrationsDocument, {
    fetchPolicy: 'network-only',
    variables: {
      page: 1,
      perPage: 100,
    },
  });

  const onSave = async () => {
    try {
      const { data } = await createBackup({
        variables: {
          input: {
            integrationId: selectedIntegrationId,
            backupParams: {},
          },
        },
      });

      if (data?.createBackup?.success) {
        navigate(`/backups/${data?.createBackup?.backup?.id}/members`);
        writeNewBackupsCache({ perPage: 100 }, data?.createBackup?.backup as PartialDeep<IBackup>);
      } else throw new ClientError(data?.createBackup?.errors?.[0]);
    } catch (error: unknown) {
      getApiError(error, message.error);
      throw error;
    }
  };

  const onConnect = () => navigate('/integrations');

  const filteredIntegrations =
    data.backupReadyIntegrations.nodes.filter((backup) => {
      const { isClientOwner, backupable, __typename } = backup as IIntegrationInterface & {
        isClientOwner: boolean;
        __typename: string;
      };

      return (backupable || !isClientOwner) && __typename !== 'ShareFileIntegration';
    }) ?? [];

  return (
    <Fragment>
      <JourneyLayout.Main className="ck-backup__main">
        <div className="d-flex justify-content-between align-items-end ck-backup__new-header">
          <TextBlock
            as="h2"
            titleColor="light-blue"
            title={filteredIntegrations.length ? 'Select Platform' : 'Connect Platform'}
            text="Please choose the platform for your backup from the options below."
          />
          {Boolean(filteredIntegrations.length) && (
            <Button type="primary" onClick={() => setIsModalProceedOpen(true)}>
              Connect New Platform
            </Button>
          )}
        </div>

        {filteredIntegrations.length ? (
          <div className="ck-backup__container mt-4">
            <BackupPlatformRadioGroup
              items={getIntegrationItems(filteredIntegrations)}
              onClick={setSelectedIntegrationId}
              selectedItemId={selectedIntegrationId}
              heading="Connected integrations"
            />
          </div>
        ) : (
          <BackupEmpty type="connect" className="mt-50" />
        )}
      </JourneyLayout.Main>

      <JourneyFooter justify="end">
        <JourneyFooter.Item>
          <Button
            type="primary"
            disabled={!filteredIntegrations.length || !selectedIntegrationId}
            onClick={onSave}
            loading={loading}
          >
            Save & Next
          </Button>
        </JourneyFooter.Item>
      </JourneyFooter>

      <ModalProceed
        isOpen={isModalProceedOpen}
        title="Connect new platform"
        text="Connecting a new platform is done in the Integrations page. Would you like to continue?"
        proceedText="Open Integrations Page"
        onProceed={onConnect}
        onCancel={() => setIsModalProceedOpen(false)}
      />
    </Fragment>
  );
};

export default BackupPlatformNewContainer;

function getIntegrationItems(integrations: IBackupReadyIntegrationsQuery['backupReadyIntegrations']['nodes']) {
  return integrations.map((item) => {
    const { id, provider, accountName, isClientOwner, __typename } = item as IOauthIntegration | ICustomIntegration;

    return {
      id,
      platform: provider,
      name: accountName || '',
      disabled: !isClientOwner,
      type: __typename ?? '',
    };
  });
}
