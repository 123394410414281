import { ShieldCheck } from 'lucide-react';

import type { IHomeBanner } from '../ui/HomeBanner';

import { solutions } from './solutions.enum';

export const organizationStatusMap: Record<
  (typeof solutions)[number] | 'empty',
  Omit<IHomeBanner, 'className' | 'subtitle'> & {
    certificateUrl?: string;
  }
> = {
  empty: {
    title: {
      text: 'Welcome to Codekeeper',
    },
    description: 'Set up your software continuity solution below.',
    bgColor: 'bg-[linear-gradient(90deg,#E1FEFF_0%,#F5FFFF_100%)]',
  },
  backup: {
    title: {
      text: 'Ready for Resilience',
      weight: 'bold' as const,
    },
    textColor: 'white' as const,
    description: 'Your organization is continuously protected with automatic, up-to-date backups.',
    bgColor: 'bg-[linear-gradient(90deg,#37ADC2_0%,#8DDDE3_79.86%),linear-gradient(90deg,#013685_0%,#1AAAF4_100%)]',
    icon: ShieldCheck,
    certificateUrl: '/backups',
  },
  escrow: {
    title: {
      text: 'Ready for Recovery',
      weight: 'bold' as const,
    },
    textColor: 'white' as const,
    description: 'Your organization has all your required assets ready for quick recovery.',
    bgColor: 'bg-[linear-gradient(90deg,#0093B0_0%,#55C6DA_100%),linear-gradient(90deg,#013685_0%,#1AAAF4_100%)]',
    icon: ShieldCheck,
    certificateUrl: '/escrows',
  },
  saas: {
    title: {
      text: 'Ready for Continuity',
      weight: 'bold' as const,
    },
    textColor: 'white' as const,
    description: 'Your organization has continuity protection for cloud-native applications.',
    bgColor: 'bg-[linear-gradient(90deg,#316ABF_0%,#22B4FF_100%),linear-gradient(90deg,#013685_0%,#1AAAF4_100%)]',
    icon: ShieldCheck,
    certificateUrl: '/saas-escrows',
  },
  custodian: {
    title: {
      text: 'Full Life Cycle',
      weight: 'bold' as const,
    },
    textColor: 'white' as const,
    description: 'Your organization has end-to-end accountability for your digital assets life cycle.',
    bgColor: 'bg-[linear-gradient(90deg,#013685_0%,#1AAAF4_100%)]',
    icon: ShieldCheck,
    certificateUrl: '/custodian-profiles',
  },
};
