import { HomeContainer, MigrationAlertContainer } from 'src/containers/Home';
import { Main } from 'src/components/Layout';
import { withSuspense } from 'src/hoc/withSuspense';
import { NotificationContainer } from 'src/containers/Notification';

const HomePage = () => {
  return (
    <Main title="Home" headerChildren={<MigrationAlertContainer />}>
      <div className="container mx-auto">
        <HomeContainer />

        <div className="rounded-lg bg-card p-6 border border-border shadow-sm">
          <h3 className="text-base font-bold text-foreground mb-4">Latest in your Organization</h3>
          <NotificationContainer interacted={false} />
        </div>
      </div>
    </Main>
  );
};

export default withSuspense(HomePage);
