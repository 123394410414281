import { useSuspenseQuery } from '@apollo/client';
import { useNavigate } from 'react-router';
import { Fragment } from 'react/jsx-runtime';
import { useState } from 'react';

import { CustodianList } from 'src/components/Custodian/List';
import { CustodianProfileStatusEnum } from 'src/components/Custodian/model';
import { CustodianEmpty } from 'src/components/Custodian/Empty';
import { CustodianProfilesDocument } from 'src/graphql/schema';
import { withSuspense } from 'src/hoc';

import CustodianRemoveContainer from '../Remove/CustodianRemoveContainer';
import { CreateCustodianProfileContainer } from '../';

import type { ICustodianProfilesQuery } from 'src/graphql/schema';

const CustodianListContainer = () => {
  const [removeBackupId, setRemoveBackupId] = useState('');

  const { data } = useSuspenseQuery<ICustodianProfilesQuery>(CustodianProfilesDocument);
  const navigate = useNavigate();

  const dropdownItemClick = (type: string, id: string) => {
    switch (type) {
      case 'view':
        navigate(`/custodian-profiles/${id}`);
        break;
      case 'remove':
        setRemoveBackupId(id);
        break;
      default:
        throw new Error('Unknown action type');
    }
  };

  if (!data.custodianProfiles.nodesCount)
    return (
      <div className="d-flex align-items-center justify-content-center ck-custodian__empty">
        <CustodianEmpty action={<CreateCustodianProfileContainer size="large" btnText="Create New Profile" />} />
      </div>
    );

  const dataItems = data?.custodianProfiles.nodes.map(getCustodianNode);

  const resetRemoveBackupId = () => setRemoveBackupId('');

  return (
    <Fragment>
      <CustodianList items={dataItems} dropdownItemClick={dropdownItemClick} />

      <CustodianRemoveContainer
        isOpen={Boolean(removeBackupId)}
        profileId={removeBackupId}
        closeModal={resetRemoveBackupId}
      />
    </Fragment>
  );
};

function getCustodianNode({
  id,
  name,
  status,
  custodianAssets,
  custodianIntegrations,
  custodianOperations,
  custodianTermination,
  policy: { remove: canRemove },
}: ICustodianProfilesQuery['custodianProfiles']['nodes'][0]) {
  return {
    id,
    name,
    status: custodianTermination ? CustodianProfileStatusEnum.TerminationRequested : status,
    canRemove,
    assetsCount: custodianAssets.length,
    operationsCount: custodianOperations.length,
    providersCount: custodianIntegrations.length,
  };
}

export default withSuspense(CustodianListContainer, CustodianList.Skeleton);
