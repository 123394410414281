export const cookieStore = {
  get(key: string) {
    return document.cookie.split('; ').reduce(
      (acc, cookie) => {
        const [cookieKey, value] = cookie.split('=');
        if (cookieKey === key) {
          return { value };
        }
        return acc;
      },
      undefined as { value: string } | undefined,
    );
  },
};
