import UnprotectedRoute from 'src/components/UnprotectedRoute/UnprotectedRoute';
import LoginLayout from 'src/components/Authentication/Login/LoginLayout';

import Login from '../pages/Login/Login.tsx';
import SetPassword from '../pages/SetPassword/SetPassword.tsx';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword.tsx';
import TwoFAPage from '../pages/2FA/TwoFAPage.tsx';

import type { RouteObject } from 'react-router';

export const authRoutes: RouteObject = {
  element: (
    <UnprotectedRoute>
      <LoginLayout />
    </UnprotectedRoute>
  ),
  children: [
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: '/set-password',
      element: <SetPassword />,
    },
    {
      path: '/auth/2fa',
      element: <TwoFAPage />,
    },
  ],
};
