import { useNavigate } from 'react-router';
import { Fragment, useState } from 'react';
import { useReadQuery } from '@apollo/client';

import { BackupList } from 'src/components/Backup/List';
import { BackupEmpty } from 'src/components/Backup/Empty';
import { updateBackupsCache, removeBackupCache } from 'src/graphql/client/cache/backups';
import { withSuspense } from 'src/hoc';
import { BackupStatusEnum } from 'src/graphql/schema';
import { BACKUPS_LIMIT } from 'src/shared/constants';

import { BackupRemoveContainer } from '../Backup';

import type { IBackupItem } from 'src/components/Backup/List';
import type { IBackupsQuery, IBackup } from 'src/graphql/schema';
import type { QueryRef } from '@apollo/client';
import type { PartialDeep } from 'type-fest';

const BackupsContainer = ({ queryRef }: { queryRef: QueryRef<IBackupsQuery> }) => {
  const [removeBackupId, setRemoveBackupId] = useState('');

  const navigate = useNavigate();
  const { data } = useReadQuery<IBackupsQuery>(queryRef);

  if (!data.backups.nodesCount)
    return (
      <div className="d-flex align-items-center justify-content-center ck-backup__empty">
        <BackupEmpty type="new" className="mt-50" />
      </div>
    );

  const dropdownItemClick = (type: string, id: string) => {
    switch (type) {
      case 'view':
        navigate(`/backups/${id}/platform`);
        break;
      case 'remove':
        setRemoveBackupId(id);
        break;
      default:
        throw new Error('Unknown dropdown item type');
    }
  };

  const resetBackupId = () => setRemoveBackupId('');

  const successCallback = (backup: PartialDeep<IBackup>) =>
    backup.status === BackupStatusEnum.Draft
      ? removeBackupCache({ perPage: BACKUPS_LIMIT }, backup?.id || '')
      : updateBackupsCache({ perPage: BACKUPS_LIMIT }, backup);

  return (
    <Fragment>
      <BackupList items={data.backups.nodes.map(getBackup) ?? []} dropdownItemClick={dropdownItemClick} />

      <BackupRemoveContainer
        isOpen={Boolean(removeBackupId)}
        backupId={removeBackupId}
        closeModal={resetBackupId}
        successCallback={successCallback}
      />
    </Fragment>
  );
};

export default withSuspense(BackupsContainer, BackupList.Skeleton);

function getBackup(backup: IBackupsQuery['backups']['nodes'][0]): IBackupItem {
  return {
    id: backup.id,
    status: backup.status,
    integration: {
      accountName: backup.integration.accountName || '',
      provider: backup.integration.provider,
    },
    assetsCount: backup.backupAssets.length || 0,
    canRemove: backup.policy.destroy,
  };
}
