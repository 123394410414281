import { Button } from 'antd';
import { useNavigate } from 'react-router';
import { startTransition } from 'react';

import { Image404 } from 'src/components/Svg/Images';

import styles from './NotFound.module.scss';

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    startTransition(() => {
      navigate('/');
    });
  };

  return (
    <div className={styles.block}>
      <Image404 className={styles.image} />
      <h1 className={styles.heading}>Page not found</h1>
      <p className={styles.text}>
        Please check the URL for spelling.
        <br />
        You can also visit our home page or go back
      </p>
      <Button type="primary" size="large" className={styles.button} onClick={handleGoHome}>
        Home Page
      </Button>
    </div>
  );
};

export default NotFound;
